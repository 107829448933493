import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classname from 'classname';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from 'context';

import useMedia from 'tf-features/hooks/useMedia';

import styles from '../../../tf-pages/home/sections/InvestorsAndPartners/index.style';

const useStyles = makeStyles(styles);

const SectionTitle = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      {/* <span className={classes.roadMapDividerLeft}></span> */}
      <span
        className={classes.sectionTitleLeft}
      >
        {props.leftText}
      </span>
      <span>&nbsp;&nbsp;</span>
      <span
        className={classes.sectionTitleRight}
      >
        {props.rightText}
      </span>
      {/* <span className={classes.roadMapDividerRight}></span> */}
    </div>
  );
}

const SectionTitleCN = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      {/* <span className={classes.roadMapDividerLeft}></span> */}
      <span className={classes.bottomSectionTitle}>
        {props.leftText}
        {props.rightText}
      </span>
      {/* <span className={classes.roadMapDividerRight}></span> */}
    </div>
  );
}

export default () => {
  const { isNightMode } = useContext(GlobalContext);

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const imgWidth = useMedia(
    ['(max-width: 640px)', '(max-width: 960px)','(max-width: 1200px)'],
    [120, 160, 180],
    180,
  );

  const language = i18n.language.split('-')[0]?.toLowerCase();

  const investors = [
    require(`assets/img/logo/bondly@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/DUCKDAO@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/BLOCKSYNC 2@${
      isNightMode ? 'dark' : 'light'
    }.png`),
    require(`assets/img/logo/BSCPad@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/NEWAVE@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/DEFI TIMES@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/blpe32@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/7 O'Clock@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/Mint Ventures@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/MYIE VC@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/WORSHIPPER CAPITAL-LOGO@${isNightMode ? 'dark' : 'light'}.png`),
    // require(`assets/img/logo/链向财经@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/APE CAPITAL@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/Damolabs@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/MOONHALE@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/UNIONLOCK VC@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/chainfor@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/Kryptos@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/Wisteria Capital@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/JY Capital@${isNightMode ? 'dark' : 'light'}.png`),
  ];


  const partners = [
    require(`assets/img/logo/bondly@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/DUCKDAO@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/BSCPad@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/${language === 'zh' ? '币牛牛' : '编组'}@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/7 O'Clock@${isNightMode ? 'dark' : 'light'}.png`),
    require(`assets/img/logo/WORSHIPPER CAPITAL-LOGO@${isNightMode ? 'dark' : 'light'}.png`),
  ]

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {language === 'zh' ? (
          <SectionTitleCN leftText={t('Strategic')} rightText={t('Investors')} />
        ) : (
          <SectionTitle leftText={t('Strategic')} rightText={t('Investors')} />
        )}
        <Grid
          container
          justify="left"
          style={{ padding: `0 0 ${imgWidth * 0.16}px` }}
        >
          {investors.map((path, i) => (
            <Grid
              item
              container
              md={3}
              xs={6}
              key={i}
              justify="center"
              alignItems="center"
            >
              <img
                src={path}
                alt={i}
                width={imgWidth}
                style={{ margin: `${imgWidth * 0.2}px 0` }}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.content}>
        {language === 'zh' ? (
          <SectionTitleCN leftText={t('Ecosystem')} rightText={t('Partners')} />
        ) : (
          <SectionTitle leftText={t('Ecosystem')} rightText={t('Partners')} />
        )}
        <Grid
          container
          justify="left"
          style={{ padding: `0 0 ${imgWidth * 0.16}px` }}
        >
          {partners.map((path, i) => (
            <Grid
              item
              container
              md={3}
              xs={6}
              key={i}
              justify="center"
              alignItems="center"
            >
              <img
                src={path}
                alt={i}
                width={imgWidth}
                style={{ margin: `${imgWidth * 0.2}px 0` }}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
