import bg from 'assets/img/logo-bg.png';
import { container } from 'assets/jss/material-kit-pro-react.js';

export default (theme) => ({
  container: {
    paddingBottom: '120px',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
    '@media (max-width: 769px)': {
      paddingBottom: '60px',
    },
  },
  content: {
    ...container
  },
  sectionTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 0 100px',
    '@media (max-width: 769px)': {
      padding: '30px 0 50px',
    },
  },
  sectionTitleLeft: {
    fontFamily: 'AlibabaPuHuiTiB',
    fontSize: '48px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    marginLeft: '40px',
    '@media (max-width: 769px)': {
      fontSize: '18px',
      marginLeft: '20px',
    },
  },
  sectionTitleRight: {
    fontFamily: 'AlibabaPuHuiTiB',
    fontSize: '48px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    marginRight: '40px',
    '@media (max-width: 769px)': {
      fontSize: '18px',
      marginRight: '20px',
    },
  },
  roadMapDividerLeft: {
    display: 'flex',
    width: '30%',
    height: '4px',
    backgroundImage: `linear-gradient(to right, transparent 0%, ${theme.palette.background.gradientEnd} 100%)`,
    borderRadius: '2px',
    '@media (max-width: 769px)': {
      height: '2px',
    },
  },
  roadMapDividerRight: {
    display: 'flex',
    width: '30%',
    height: '4px',
    backgroundImage: `linear-gradient(to left, transparent 0%, ${theme.palette.background.gradientEnd} 100%)`,
    borderRadius: '2px',
    '@media (max-width: 769px)': {
      height: '2px',
    },
  },
  bottomSectionTitle: {
    flexShrink: 0,
    fontFamily: 'PingFang-SC',
    fontSize: '48px',
    fontWeight: 'bold',
    color: theme.palette.text.iconButton,
    margin: '0 60px',
    '@media (max-width: 769px)': {
      fontSize: '18px',
      margin: '0 20px',
    },
  },
});

