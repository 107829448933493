import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classname from 'classname';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from 'context';

import useMedia from 'tf-features/hooks/useMedia';

import styles from './index.style';

const useStyles = makeStyles(styles);

const SectionTitle = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      {/* <span className={classes.roadMapDividerLeft}></span> */}
      <span
        className={classes.sectionTitleLeft}
      >
        {props.leftText}
      </span>
      <span>&nbsp;&nbsp;</span>
      <span
        className={classes.sectionTitleRight}
      >
        {props.rightText}
      </span>
      {/* <span className={classes.roadMapDividerRight}></span> */}
    </div>
  );
}

const SectionTitleCN = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      {/* <span className={classes.roadMapDividerLeft}></span> */}
      <span className={classes.bottomSectionTitle}>
        {props.leftText}
        {props.rightText}
      </span>
      {/* <span className={classes.roadMapDividerRight}></span> */}
    </div>
  );
}

export default () => {
  const { isNightMode } = useContext(GlobalContext);

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const language = i18n.language.split('-')[0]?.toLowerCase();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {language === 'zh' ? (
          <SectionTitleCN leftText={t('Token')} rightText={t('Information')} />
        ) : (
          <SectionTitle leftText={t('Token')} rightText={t('Information')} />
        )}
        <Grid
          container
          justify="center"
        >
        <Grid
              item
              container
              md={3}
              xs={12}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <img src={require('assets/img/about/token.png')}></img>
            </Grid>
            <Grid
              item
              container
              md={3}
              xs={4}
              flexDirection="column"
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
                {t('Token Name')}
              </div>
              <div className={classes.infocontent}>
              {t('TheForce Coin')}
              </div>
            </Grid>
            
            <Grid
              item
              container
              md={3}
              xs={4}
              flexDirection="column"
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Token symbol')}
              </div>
              <div className={classes.infocontent}>
              FOC
              </div>
            </Grid>
            
            <Grid
              item
              container
              md={3}
              xs={4}
              flexDirection="column"
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Total Supply')}
              </div>
              <div className={classes.infocontent}>
              1,000,000,000 FOC
              </div>
            </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        {language === 'zh' ? (
          <SectionTitleCN leftText={t('Token')} rightText={t('Allocation')} />
        ) : (
          <SectionTitle leftText={t('Token')} rightText={t('Allocation')} />
        )}
        <Grid
          container
          justify="left"
        >
          <Grid
              item
              container
              md={3}
              xs={6}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Staking Rewards')}
              </div>
              <div className={classes.infocontent}>
              59.5%
              </div>
            </Grid>
            <Grid
              item
              container
              md={3}
              xs={6}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Private & Public Sale')}
              </div>
              <div className={classes.infocontent}>
              5.5%
              </div>
            </Grid>
            <Grid
              item
              container
              md={3}
              xs={6}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Team & Advisers')}
              </div>
              <div className={classes.infocontent}>
              5.00%
              </div>
            </Grid>
            <Grid
              item
              container
              md={3}
              xs={6}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Community Rewards')}
              </div>
              <div className={classes.infocontent}>
              20.0%
              </div>
            </Grid>
            <Grid
              item
              container
              md={3}
              xs={6}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Marketing & Partnerships')}
              </div>
              <div className={classes.infocontent}>
              3.0%
              </div>
            </Grid>
            <Grid
              item
              container
              md={3}
              xs={6}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Liquidity Reserve')}
              </div>
              <div className={classes.infocontent}>
              5.0%
              </div>
            </Grid>
            <Grid
              item
              container
              md={3}
              xs={6}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infotitle}>
              {t('Operations & Maintenance')}
              </div>
              <div className={classes.infocontent}>
              2.00%
              </div>
            </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <div className={classes.mySectionTitle}>
        {
          t("ReleaseSchedule")
        }
        </div>
        <Grid
          container
          justify="center"
        >
        <Grid
              item
              container
              md={3}
              xs={12}
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <img src={require('assets/img/about/token.png')}></img>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              flexDirection="column"
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infocontent}>
              {t("Schedule")}
              </div> 
            </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <div className={classes.mySectionTitle}>
        {t('Team Background')}
        </div>
        <Grid
          container
          justify="center"
        >
            <Grid
              item
              container
              xs={12}
              flexDirection="column"
              justify="center"
              alignItems="center"
              className={classes.tokengrid}
            >
              <div className={classes.infocontent}>
                {t("TeamBackgroundContent")}
              </div> 
            </Grid>
        </Grid>
      </div>
      
    </div>
  );
};
