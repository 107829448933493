export const hecoPools = [
  {
    id: 'mdex-hbtc-wht',
    logo: 'heco/HBTC-WHT.svg',
    name: 'HBTC-WHT LP',
    token: 'HBTC-WHT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xBFff969A85e355eE0851b019Dba1e87C7780F40d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHBTC-WHT',
    earnedTokenAddress: '0xFDafEA4529d609901E6E6CC65b3e2C1C822e223d',
    earnContractAddress: '0xFDafEA4529d609901E6E6CC65b3e2C1C822e223d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-hbtc-wht',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HBTC', 'WHT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-eth-wht',
    logo: 'heco/ETH-WHT.svg',
    name: 'ETH-WHT LP',
    token: 'ETH-WHT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x53E458aD1CFEB9582736db6BdE9aF89948e3bc3d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexETH-WHT',
    earnedTokenAddress: '0x07AD2C13a0D735FA4F8788DC0B6355AaaB2f3407',
    earnContractAddress: '0x07AD2C13a0D735FA4F8788DC0B6355AaaB2f3407',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-eth-wht',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['ETH', 'WHT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-husd-usdt',
    logo: 'heco/HUSD-USDT.svg',
    name: 'HUSD-USDT LP',
    token: 'HUSD-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xdff86B408284dff30A7CAD7688fEdB465734501C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHUSD-USDT',
    earnedTokenAddress: '0xe6CcE165Aa3e52B2cC55F17b1dBC6A8fe5D66610',
    earnContractAddress: '0xe6CcE165Aa3e52B2cC55F17b1dBC6A8fe5D66610',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-husd-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HUSD', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-hltc-usdt',
    logo: 'heco/HLTC-USDT.svg',
    name: 'HLTC-USDT LP',
    token: 'HTLC-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x060B4bfcE16D15A943ec83C56C87940613e162eB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHLTC-USDT',
    earnedTokenAddress: '0x41D44B276904561Ac51855159516FD4cB2c90968',
    earnContractAddress: '0x41D44B276904561Ac51855159516FD4cB2c90968',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-hltc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HLTC', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-hbch-usdt',
    logo: 'heco/HBCH-USDT.svg',
    name: 'HBCH-USDT LP',
    token: 'HBCH-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x1f0eC8e0096e145f2bf2Cb4950Ed7b52d1cbd35f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHBCH-USDT',
    earnedTokenAddress: '0xdf68Bf80D427A5827Ff2c06A9c70D407e17DC041',
    earnContractAddress: '0xdf68Bf80D427A5827Ff2c06A9c70D407e17DC041',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-hbch-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HBCH', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-hdot-usdt',
    logo: 'heco/HDOT-USDT.svg',
    name: 'HDOT-USDT LP',
    token: 'HDOT-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x5484ab0DF3E51187f83F7f6b1a13f7a7Ee98C368',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHDOT-USDT',
    earnedTokenAddress: '0x2a30C5e0d577108F694d2A96179cd73611Ee069b',
    earnContractAddress: '0x2a30C5e0d577108F694d2A96179cd73611Ee069b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-hdot-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HDOT', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-hfil-usdt',
    logo: 'heco/HFIL-USDT.svg',
    name: 'HFIL-USDT LP',
    token: 'HFIL-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x600072aF0470d9Ed1D83885D03d17368943fF22A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHFIL-USDT',
    earnedTokenAddress: '0x1433c4a55449c8B96cf5Ac0cF395cBd8dc8e5f60',
    earnContractAddress: '0x1433c4a55449c8B96cf5Ac0cF395cBd8dc8e5f60',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-hfil-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HFIL', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-hpt-usdt',
    logo: 'heco/HPT-USDT.svg',
    name: 'HPT-USDT LP',
    token: 'HPT-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xdE5b574925EE475c41b99a7591EC43E92dCD2fc1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHPT-USDT',
    earnedTokenAddress: '0x7a670e849DB824364d1031DEAfB4cD603144F23D',
    earnContractAddress: '0x7a670e849DB824364d1031DEAfB4cD603144F23D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-hpt-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HPT', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-lhb-usdt',
    logo: 'heco/LHB-USDT.svg',
    name: 'LHB-USDT LP',
    token: 'LHB-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x023f375a51Af8645D7446ba5942BAeDc53B0582D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexLHB-USDT',
    earnedTokenAddress: '0x945b2379E29F503a78dBcaB2feEFFE74a6c31E2b',
    earnContractAddress: '0x945b2379E29F503a78dBcaB2feEFFE74a6c31E2b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-lhb-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['LHB', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-aave-usdt',
    logo: 'heco/AAVE-USDT.svg',
    name: 'AAVE-USDT LP',
    token: 'AAVE-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xfAfeAafeFc5F92F22415506e78D9AB1E33C03257',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexAAVE-USDT',
    earnedTokenAddress: '0x6169551074826724CAcd8Deb452BF133403c2036',
    earnContractAddress: '0x6169551074826724CAcd8Deb452BF133403c2036',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-aave-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['AAVE', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-snx-usdt',
    logo: 'heco/SNX-USDT.svg',
    name: 'SNX-USDT LP',
    token: 'SNX-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xc7A4C808a29fc8Cd3A8a6848f7F18bED9924C692',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexSNX-USDT',
    earnedTokenAddress: '0x8B1Ca7f3F0838dCd23DA8CFe223eA313739193cb',
    earnContractAddress: '0x8B1Ca7f3F0838dCd23DA8CFe223eA313739193cb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-snx-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['SNX', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-link-usdt',
    logo: 'heco/LINK-USDT.svg',
    name: 'LINK-USDT LP',
    token: 'LINK-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x52a342015BAa2496A90A9bB6069D7692564132e6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexLINK-USDT',
    earnedTokenAddress: '0x044e87f30bd9bD961c04028aC69155493E1b9eD0',
    earnContractAddress: '0x044e87f30bd9bD961c04028aC69155493E1b9eD0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-link-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['LINK', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-bal-usdt',
    logo: 'heco/BAL-USDT.svg',
    name: 'BAL-USDT LP',
    token: 'BAL-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xB6A77CDD31771A4F439622aA36b20cb53C19868C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexBAL-USDT',
    earnedTokenAddress: '0x666c0b9D37A20235C232081C1C6B2edc70ecC7F3',
    earnContractAddress: '0x666c0b9D37A20235C232081C1C6B2edc70ecC7F3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-bal-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['BAL', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-yfi-usdt',
    logo: 'heco/YFI-USDT.svg',
    name: 'YFI-USDT LP',
    token: 'YFI-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x64Af3564C6D6BEc5883358c560211EcD0f8d1AC7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexYFI-USDT',
    earnedTokenAddress: '0xbF7421bd2f79643a671b70d1DDE57D452C110CF8',
    earnContractAddress: '0xbF7421bd2f79643a671b70d1DDE57D452C110CF8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-yfi-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['YFI', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-uni-usdt',
    logo: 'heco/UNI-USDT.svg',
    name: 'UNI-USDT LP',
    token: 'UNI-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x84455d880af684eb29997B82832dd71EF29c1354',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexUNI-USDT',
    earnedTokenAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    earnContractAddress: '0xC422261EdC5dB679CAd9BC403e886351De540e77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-uni-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['UNI', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-mdx-usdt',
    logo: 'heco/MDX-USDT.png',
    name: 'MDX-USDT LP',
    token: 'MDX-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x615E6285c5944540fd8bd921c9c8c56739Fd1E13',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexMDX-USDT',
    earnedTokenAddress: '0x5c2197149ce7CAb038aB09C45087a09070E32C73',
    earnContractAddress: '0x5c2197149ce7CAb038aB09C45087a09070E32C73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-mdx-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['MDX', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-mdx-wht',
    logo: 'heco/MDX-WHT.png',
    name: 'MDX-WHT LP',
    token: 'MDX-WHT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x6Dd2993B50b365c707718b0807fC4e344c072eC2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexMDX-WHT',
    earnedTokenAddress: '0xd93A86BbF40454A7BCD339614fB46C67bE31B908',
    earnContractAddress: '0xd93A86BbF40454A7BCD339614fB46C67bE31B908',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-mdx-wht',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['MDX', 'WHT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-wht-usdt',
    logo: 'heco/WHT-USDT.png',
    name: 'WHT-USDT LP',
    token: 'WHT-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x499B6E03749B4bAF95F9E70EeD5355b138EA6C31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexWHT-USDT',
    earnedTokenAddress: '0x2f536faCbC780B9ccA02545d2aA71021d7308c5E',
    earnContractAddress: '0x2f536faCbC780B9ccA02545d2aA71021d7308c5E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-wht-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['WHT', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-wht-husd',
    logo: 'heco/WHT-HUSD.png',
    name: 'WHT-HUSD LP',
    token: 'WHT-HUSD LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x3375afF2CAcF683b8FC34807B9443EB32e7Afff6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexWHT-HUSD',
    earnedTokenAddress: '0x3492eF16b2ACb5b5240436d5D2B657fDc2b0b1eB',
    earnContractAddress: '0x3492eF16b2ACb5b5240436d5D2B657fDc2b0b1eB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-wht-husd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['WHT', 'HUSD'],
    callFee: 0.25,
  },

  {
    id: 'mdex-hbtc-usdt',
    logo: 'heco/HBTC-USDT.png',
    name: 'HBTC-USDT LP',
    token: 'HBTC-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0xFBe7b74623e4be82279027a286fa3A5b5280F77c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexHBTC-USDT',
    earnedTokenAddress: '0x1Ff05E1Fb13931eBE19363441bF10f8c5dCc963E',
    earnContractAddress: '0x1Ff05E1Fb13931eBE19363441bF10f8c5dCc963E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-hbtc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['HBTC', 'USDT'],
    callFee: 0.25,
  },

  {
    id: 'mdex-eth-usdt',
    logo: 'heco/ETH-USDT.png',
    name: 'ETH-USDT LP',
    token: 'ETH-USDT LP',
    tokenDescription: 'Mdex',
    tokenAddress: '0x78C90d3f8A64474982417cDB490E840c01E516D4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMdexETH-USDT',
    earnedTokenAddress: '0x56Fb7dA3025f76d2128Ef1b0D2EEA47Dd45e7C2a',
    earnContractAddress: '0x56Fb7dA3025f76d2128Ef1b0D2EEA47Dd45e7C2a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'mdex-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Mdex',
    assets: ['ETH', 'USDT'],
    callFee: 0.25,
  },
];
