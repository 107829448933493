import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import GlobalContext from 'context';

import styles from './index.style';


const useStyles = makeStyles(styles);

export default () => {
  const { isNightMode } = useContext(GlobalContext);
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const language =
    i18n.language.split('-')[0]?.toLowerCase() === 'zh' ? 'zh' : 'en';

  return (
    <div className={classes.highlight}>
         <div className={classes.title}>
           {t('Highlight')}
         </div>
         <Grid
          container
          justify="center"
          className={classes.container}
        >
            <Grid
              item
              container
              md={6}
              xs={12}
              justify="center"
              alignItems="center"
            >
              <div className={classes.highitem}>
                 <img src={require('assets/img/highlight/1.png')} className={classes.highicon}/>
                 <div className={classes.textcontent}>
                    <div className={classes.contenttitle}>
                    {t("HighlightTitle1")}
                    </div>
                    <div className={classes.contentdetail}>
                    {t("HighlightContent1")}
                    </div>
                 </div>
              </div>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              justify="center"
              alignItems="center"
            >
               <div className={classes.highitem}>
                 <img src={require('assets/img/highlight/2.png')} className={classes.highicon}/>
                 <div className={classes.textcontent}>
                    <div className={classes.contenttitle}>
                    {t("HighlightTitle2")}
                    </div>
                    <div className={classes.contentdetail}>
                    {t("HighlightContent2")}
                    </div>
                 </div>
              </div>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              justify="center"
              alignItems="center"
            >
               <div className={classes.highitem}>
                 <img src={require('assets/img/highlight/3.png')} className={classes.highicon}/>
                 <div className={classes.textcontent}>
                    <div className={classes.contenttitle}>
                    {t("HighlightTitle3")}
                    </div>
                    <div className={classes.contentdetail}>
                    {t("HighlightContent3")}
                    </div>
                 </div>
              </div>
            </Grid>
            <Grid
              item
              container
              md={6}
              xs={12}
              justify="center"
              alignItems="center"
            >
               <div className={classes.highitem}>
                 <img src={require('assets/img/highlight/4.png')} className={classes.highicon}/>
                 <div className={classes.textcontent}>
                    <div className={classes.contenttitle}>
                    {t("HighlightTitle4")}
                    </div>
                    <div className={classes.contentdetail}>
                    {t("HighlightContent4")}
                    </div>
                 </div>
              </div>
            </Grid>
        </Grid>
    </div>
  );
};
