
import { container } from 'assets/jss/material-kit-pro-react.js';

export default (theme) => ({
    faq: {
      background: '#010029',
      color: theme.palette.text.primary
    },
    container:{
      ...container
    },
    title: {
      fontWeight:  '900',
      fontSize: '36px',
      textAlign: 'center',
      padding: '60px 0',
      '@media (max-width: 769px)': {
        padding: '50px 0',
        fontSize: '18px',
      },
    },
    head: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '32px 0',
      borderTop: '1px solid #353945',
      wordBreak: 'break-all',
      whiteSpace: 'pre-wrap',
      cursor: 'pointer',
      '@media (max-width: 769px)': {
        padding: '20px 0',
        fontSize: '14px',
      },
    },
    content: {
      fontSize: '16px',
      display: 'flex',
      width: '100%',
      padding: '0 0 32px 0',
      color: '#777E90',
      width: '100%',
      lineHeight: '23px',
      // wordBreak: 'break-all',
      whiteSpace: 'pre',
      whiteSpace: 'pre-wrap',
      '@media (max-width: 769px)': {
        padding: '20px 0',
        fontSize: '14px',
      },
    }
});
