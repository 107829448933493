import React, { useState, useContext } from 'react';
import classname from 'classname';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Header from 'tf-components/Header/Header';
import { SnackbarProvider } from 'notistack';
import { Notifier } from 'tf-features/common';

import { useTranslation } from 'react-i18next';
import appStyle from 'common/appStyle';
import createTheme from 'common/appTheme';
import GlobalContext from './context';

const useStyles = makeStyles(appStyle);

const MenuIcon = () => {
  const classes = useStyles();
  const { isNightMode } = useContext(GlobalContext);
  const handleClick = (url) => () => window.open(url);
  return (
    <div className={classes.menuBarIcon}>
      <a href="https://twitter.com/TheforceTrade" target="_blank" className={classes.linkicon}><img
        src={require('assets/img/link/twitter.png')}
        width={'32px'}
        alt="img1"
      /></a>
      <a href="https://discord.gg/p7cZ29V7Nh"  target="_blank" className={classes.linkicon}><img
        src={require('assets/img/link/discord.png')}
        width={'34px'}
        alt="img2"
      /></a>
      <a href="https://t.me/theforcetrade1"  target="_blank" className={classes.linkicon}><img
        src={require('assets/img/link/telegram.png')}
        width={'38px'}
        alt="img3"
      /></a>
      <a href="https://theforcetrade.medium.com"  target="_blank" className={classes.linkicon}><img
        src={require('assets/img/link/medium.png')}
        width={'45px'}
        alt="img4"
      /></a>
      <a href="https://github.com/TheForce-Trade"  target="_blank" className={classes.linkicon}><img
        src={require(`assets/img/link/github.png`)}
        width={'31px'}
        alt="img5"
      /></a>
    </div>
  );
};

export default function App({ children }) {

  const classes = useStyles();

  const [isNightMode, setNightMode] = useState('dark');
  const theme = createTheme(isNightMode);
  const {t} = useTranslation()
  const toggleNightMode = () => {
    localStorage.setItem('theme', isNightMode ? 'light' : 'dark');
    setNightMode(!isNightMode);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalContext.Provider value={{ isNightMode, setNightMode }}>
          <SnackbarProvider>
            <div
              className={classname(
                classes.page,
                isNightMode ? classes.darkPage : classes.lightPage,
              )}
              style={{
                backgroundColor: theme.palette.background.default,
              }}
            >
              <Header
                isNightMode={isNightMode}
                toggleNightMode={toggleNightMode}
              />
              {/* <div className={classes.container}> */}
                <div className={classes.children}>
                   {children}
                  <Notifier />
                </div>
              {/* </div> */}
              <div
                className={classes.footer}
                style={{ backgroundColor: theme.palette.background.label }}
              >
                <div className={classes.footerContent}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className={classes.footerContentLeft}
                    >
                      <img
                        alt="small"
                        src={require(`assets/img/logo@${
                          isNightMode ? 'dark' : 'light'
                        }.large.png`)}
                        className={classname(classes.footerLogo, classes.logo)}
                      />
                      <MenuIcon />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.footerContentRight}
                    >
                      <span
                        className={classes.footerText}
                        style={{
                          color: theme.palette.text.primary,
                        }}
                      >
                        © 2021 TheForce.Trade. 
                            <a href="" className={classes.linka}>
                            Privacy policy
                            </a>
                            <a href=""  className={classes.linka}>
                            Term of service
                            </a>
                            <a  className={classes.linka} href="https://docs.theforce.trade/protocol/audit-report">
                            {
                              t("Audit-Report")
                            }
                            </a>
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </SnackbarProvider>
        </GlobalContext.Provider>
      </ThemeProvider>
    </StylesProvider>
  );
}
