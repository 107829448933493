
import { container } from 'assets/jss/material-kit-pro-react.js';
import Banner from 'assets/img/bg/bg-banner.png';

export default (theme) => ({
    banner: {
      width: '100%',
      height: '1150px',
      backgroundImage: `url(${Banner})`,
      backgroundSize: '1920px 1150px',
      backgroundSize: 'cover',
      backgroundRepeat:'no-repeat',
      backgroundPosition: 'center',
      '@media (max-width: 769px)': {
        backgroundImage: `url(${Banner})`,
        backgroundSize: '796px 500px',
        height: '500px',
      },
      marginTop: '-72px',
      color: theme.palette.text.primary,
    },
    container:{
      ...container,
      zIndex: 1
    },
    littleTitle: {
      paddingTop: '300px',
      fontSzie: '13px',
      textAlign: "center",
      '@media (max-width: 769px)': {
      fontSzie: '12px',
      paddingTop: '120px',
     },
    },
    content: {
     fontWeight: 800,
      paddingTop: '60px',
      fontSize: '45px',
      textAlign: "center",
     lineHeight: '50px',
     '@media (max-width: 769px)': {
      fontSize: '25px',
      lineHeight: '30px',
      paddingTop: '20px',
    },
    },
    extra: {
      fontSize: '25px',
      textAlign: "center",
      lineHeight: '35px',
      paddingTop: '60px',
      '@media (max-width: 769px)': {
      lineHeight: '20px',
      paddingTop: '30px',
      fontSize: '15px',
      }
    },
    menuBarAppContainer: {
      flexShrink: 0,
      flexGrow: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '30px',
    },
  menuBarApp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '180px',
    height: '48px',
    borderRadius: '24px',
    backgroundColor: '#d403ab',
    color: '#FFFFFF',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'PingFang-SC',
    margin: '0 10px',
    '&:hover,&:active,&:focus,&:visited': {
      color: '#FFFFFF',
    },
    '@media (max-width: 769px)': {
      width: '140px',
      height: '40px',
      borderRadius: '20px',
      fontSize: '14px',
    },
  },
  
  menuBarAppW: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '180px',
    height: '48px',
    borderRadius: '24px',
    backgroundColor: '#03a4c5',
    color: '#fff',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'PingFang-SC',
    margin: '0 10px',
    '&:hover,&:active,&:focus,&:visited': {
      color: '#fff',
    },
    '@media (max-width: 769px)': {
      width: '140px',
      height: '40px',
      borderRadius: '20px',
      fontSize: '14px',
    },
  },
});
