import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = (isNightMode) =>
  createMuiTheme({
    palette: {
      type: isNightMode ? 'dark' : 'light',
      background: {
        appBar: isNightMode ? 'rgba(1, 0, 41, 0.5);' : '#FFFFFF',
        iconButton: isNightMode ? '#272A35' : '#F5F7FA',
        activeIconButton: isNightMode ? '#2D3346' : '#EEF2FF',
        default: isNightMode ? '#171719' : '#F7F8FA',
        paper: isNightMode ? '#606077' : '#fff',
        primary: isNightMode ? '#505067' : '#FBF6F0',
        secondary: isNightMode ? '#3B3A4D' : '#F8F2EC',
        dark: isNightMode ? '#2B2A3D' : '#999',
        paused: isNightMode ? '#2B2A5A' : '#FCE57E',
        retired: isNightMode ? '#d32f2f' : '#e57373',
        hover: isNightMode ? '#2B2A3D' : '#EFE6DC',
        border: isNightMode ? '#2B2A3D' : '#DED9D5',
        card: isNightMode ? '#18181A' : '#FFFFFF',
        cardTvl: isNightMode ? '#313447' : '#E3F3EC',
        cardGlobal: isNightMode ? '#313447' : '#FFF4F4',
        cardInfo: isNightMode ? '#313447' : '#EEF2FF',
        positionTip: isNightMode ? '#1F1F27' : '#F6F8FD',
        label: isNightMode ? '#2C2E3F' : '#EFF1F5',
        calculator: isNightMode ? '#313447' : '#FFFFFF',
        calculatorItem: isNightMode ? '#1F1F27' : '#EFF2F5',

        gradientStart: isNightMode ? '#171719' : '#f6f7f9',
        gradientEnd: isNightMode ? '#272A35' : '#d7dae5',
      },
      border: {
        menuList: isNightMode ? '#2D2F3E' : '#E8EAF0',
        swiper: isNightMode ? '#353a3b' : '#FFFFFF',
      },
      primary: {
        main: isNightMode ? '#fff' : '#000',
      },
      secondary: {
        main: isNightMode ? '#fff' : '#F8F2EC',
      },
      text: {
        primary: isNightMode ? '#fff' : '#000',
        secondary: isNightMode ? '#B0B0DD' : '#00000066',
        iconButton: isNightMode ? '#FFFFFF' : '#333333',
        menuBar: isNightMode ? '#E1E9F0' : '#A6B0C3',
        desc: isNightMode ? '#78819C' : '#6A768C',
        sectionTitle: isNightMode ? '#FFFFFF' : '#36394D',
        title: isNightMode ? '#78819C' : '#A6B0C3',
        rating: isNightMode ? '#FFFFFF' : '#3861FB',
        description: isNightMode ? '#FFFFFF' : '#808AAA',
        tips: isNightMode ? '#484851' : '#D6D6E6',
      },
      gradient: {
        swiper: isNightMode
          ? 'linear-gradient(136deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.07)) 6 6;'
          : 'linear-gradient(136deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)) 6 6;',
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color: isNightMode ? '#fff' : '#000',
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color: isNightMode ? '#fff' : '#000',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: isNightMode ? '#fff' : '#000',
        },
        colorSecondary: {
          color: isNightMode ? '#fff' : '#000',
        },
      },
    },
  });

export default createTheme;
