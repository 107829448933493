import { container } from 'assets/jss/material-kit-pro-react.js';

import BG_LIGHT from 'assets/img/bg/bg@light.png';
import BG_DARK from 'assets/img/bg/bg@dark.png';
import BG_LIGHT_M from 'assets/img/bg/bg@light.m.png';
import BG_DARK_M from 'assets/img/bg/bg@dark.m.png';
import bg from 'assets/img/bg/footer.png';

const appStyle = (theme) => ({
  page: {
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
    backgroundRepeat: 'no-repeat',
  },
  lightPage: {
    backgroundImage: `url(${BG_LIGHT})`,
    backgroundSize: '100% 3166px',
    '@media (max-width: 600px)': {
      backgroundImage: `url(${BG_LIGHT_M})`,
      backgroundSize: '100% 4608px',
    },
  },
  darkPage: {
    // backgroundImage: `url(${BG_DARK})`,
    backgroundSize: '100% 3166px',
    '@media (max-width: 600px)': {
      backgroundImage: `url(${BG_DARK_M})`,
      backgroundSize: '100% 4608px',
    },
  },
  container: {
    ...container,
    zIndex: 1,
  },
  children: {
    minHeight: '77vh',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
  },
  footerContent: {
    width: '100%',
    maxWidth: '1230px',
  },
  footerContentLeft: {
    padding: '30px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    '@media (max-width: 600px)': {
      padding: '20px 0 5px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '10px 0',
    },
  },
  footerContentRight: {
    padding: '30px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      padding: '5px 0 20px',
      justifyContent: 'center',
    },
  },
  footerText: {
    fontFamily: 'PingFang-SC',
    fontSize: '20px',
    fontWeight: 'normal',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      fontSize: '12px',
    },
  },
  footerLogo: {
    height: '40px',
    '@media (max-width: 600px)': {
      height: '30px',
      marginBottom: '20px',
    },
  },
  menuBarIcon: {
    // width: '188px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '0 10px',
    cursor: 'pointer',
  },
  footerTextone: {
    textAlign: 'center',
  },
  linkicon: {
    margin: '0 15px'
  },
  linka: {
    margin: '0 15px',
    color: '#fff'
  }
});

export default appStyle;
