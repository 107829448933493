import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classname from 'classname';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from 'context';

import useMedia from 'tf-features/hooks/useMedia';

import styles from './index.style';

const useStyles = makeStyles(styles);

const SectionTitle = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      {/* <span className={classes.roadMapDividerLeft}></span> */}
      <span
        className={classes.sectionTitleLeft}
      >
        {props.leftText}
      </span>
      <span>&nbsp;&nbsp;</span>
      <span
        className={classes.sectionTitleRight}
      >
        {props.rightText}
      </span>
      {/* <span className={classes.roadMapDividerRight}></span> */}
    </div>
  );
}

const SectionTitleCN = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      {/* <span className={classes.roadMapDividerLeft}></span> */}
      <span className={classes.bottomSectionTitle}>
        {props.leftText}
        {props.rightText}
      </span>
      {/* <span className={classes.roadMapDividerRight}></span> */}
    </div>
  );
}

export default () => {
  const { isNightMode } = useContext(GlobalContext);

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const imgWidth = useMedia(
    ['(max-width: 640px)', '(max-width: 960px)', '(max-width: 1024px)','(max-width: 1200px)','(max-width: 1400px)'],
    [30, 30,50, 60, 77],
    77,
  );
  const imgWidthSquare = useMedia(
    ['(max-width: 640px)', '(max-width: 960px)','(max-width: 1200px)'],
    [30, 30, 50],
    50,
  );

  const language = i18n.language.split('-')[0]?.toLowerCase();

  const investors = [
    // require(`assets/img/feature/1.png`),
    // require(`assets/img/feature/2.png`),
    // require(`assets/img/feature/3.png`),
    // require(`assets/img/feature/4.png`),
    {
      name: 'Yahoo Finance',
      url: 'https://finance.yahoo.com/news/data-aggregator-theforce-launch-binance-122300020.html'
    },
    {
      name: 'Market Watch',
      url: 'https://www.marketwatch.com/press-release/data-aggregator-theforce-to-launch-on-binances-smart-chain-bscpad-2021-05-06?tesla=y'
    },
    {
      name: 'BTC Peers',
      url: 'https://btcpeers.com/data-aggregators-like-theforce-make-navigating-defi-and-nfts-much-more-convenient/'
    },
    {
      name: 'CoinMarketCap',
      url: 'https://coinmarketcap.com/currencies/theforce-trade/ico/',
    },
    {
      name: 'Entrepreneur',
      url: 'https://www.entrepreneur.com/article/371617'
    },
    {
      name: 'Hacker Noon',
      url: 'https://hackernoon.com/how-the-nft-breathes-new-life-into-branded-content-marketing-a41w340a'
    },
    {
      name: 'Cointelegraph',
      url: 'https://cointelegraph.com/press-releases/theforcetrade-launches-liquidity-pool-on-pancakeswap-after-successful-listing'
    },
    {
      name: 'BITCOINIST',
      url: 'https://bitcoinist.com/10-tokens-worth-buying-while-the-crypto-market-is-down/'
    }







 


  ];


  const partners = [
    'HowUseStep1',
    'HowUseStep2',
    'HowUseStep3',
    'HowUseStep4'
  ]

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {language === 'zh' ? (
          <SectionTitleCN leftText={t('Featured')} rightText={t('In')} />
        ) : (
          <SectionTitle leftText={t('Featured')} rightText={t('In')} />
        )}
        <Grid
          container
          justify="center"
          style={{ padding: `0 0 ${imgWidth * 0.16}px` }}
        >
          {investors.map((path, i) => (
            <Grid
              item
              container
              md={2}
              xs={4}
              key={i}
              justify="center"
              alignItems="center"
            >
              {/* <img
                src={path}
                alt={i}
                height={imgWidth}
                style={{ margin: `${imgWidth * 0.2}px 0` }}
              /> */}
              <a href={path.url} target="_blank" className={classes.logolink}>{path.name}</a>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.content}>
        {language === 'zh' ? (
          <SectionTitleCN leftText={t('HowUse')} rightText={t('')} />
        ) : (
          <SectionTitle leftText={t('HowUse')} rightText={t('')} />
        )}
        <Grid
          container
          justify="left"
          style={{ padding: `0 0 ${imgWidth * 0.16}px` }}
        >
          {partners.map((item, i) => (
            <Grid
              item
              container
              md={3}
              xs={6}
              key={i}
              justify="center"
              alignItems="start"
            >
              <div className={classes.introduce}>
                <img
                  src={require(`assets/img/Squares.png`)}
                  alt={i}
                  width={imgWidthSquare}
                  style={{ margin: `${imgWidthSquare * 0.5}px 0` }}
                />
                {t(item)}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
