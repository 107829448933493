
import { container } from 'assets/jss/material-kit-pro-react.js';

export default (theme) => ({
    highlight: {
      background: '#010029',
      color: theme.palette.text.primary
    },
    container:{
      ...container
    },
    title: {
      fontWeight:  '900',
      fontSize: '48px',
      textAlign: 'center',
      padding: '60px 0',
      '@media (max-width: 769px)': {
        padding: '50px 0',
        fontSize: '18px',
      },
    },
    textcontent: {
      marginLeft: '40px',
      maxWidth: '400px',
      '@media (max-width: 769px)': {
        marginLeft: '20px'
      }
    },
    highitem: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingBottom: '100px',
      '@media (max-width: 769px)': {
        paddingBottom: '60px'
      }
    },
    highicon: {
      width: '100px',
      height: '103px',
      '@media (max-width: 769px)': {
        width: '80px',
        height: '82px'
      }
    },
    contenttitle: {
      fontSize: '25px',
      '@media (max-width: 769px)': {
        fontSize: '16px'
      },
    },
    contentdetail: {
      fontSize: '17px',
      marginTop: '20px',
      '@media (max-width: 769px)': {
        fontSize: '12px'
      },
    }
});
