import {
  container,
  transition,
  boxShadow,
  whiteColor,
  grayColor,
} from 'assets/jss/material-kit-pro-react.js';

const styles = (theme) => ({
  appBar: {
    display: 'flex',
    border: '0',
    borderRadius: '3px',
    padding: '0',
    color: grayColor[15],
    width: '100%',
    backgroundColor: theme.palette.background.appBar,
    boxShadow: 'none',
    transition: 'all 150ms ease 0s',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    left: 0,
  },
  appBarHolder: {
    display: 'flex',
    border: '0',
    borderRadius: '3px',
    padding: '0',
    color: grayColor[15],
    width: '100%',
    minHeight: '72px',
  },
  container: {
    ...container,
    minHeight: '72px',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    '@media (min-width: 992px)': {
      width: '100%',
      maxWidth: '960px',
    },
    '@media (min-width: 1230px)': {
      width: '100%',
      maxWidth: '1230px',
    },
  },
  dark: {
    color: whiteColor,
    backgroundColor: theme.palette.background.appBar,
    boxShadow: '0px 0px',
  },
  drawerIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: '15px',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: 'unset',
    minWidth: 'unset',
    lineHeight: '28px',
    fontSize: '24px',
    fontWeight: '700',
    borderRadius: '3px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
    marginRight: '12px',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'transparent',
    },
  },
  logo: {
    transform: 'translateY(4px)',
  },
  menuBarContainer: {
    flexGrow: 1,
    flexShrink: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuBarItem: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 26px',
    color: theme.palette.text.menuBar,
    fontSize: '16px',
    fontFamily: 'PingFang-SC',
    cursor: 'pointer',
  },
  menuBarActiveItem: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 26px',
    color: '#00F0FF',
    fontSize: '16px',
    fontFamily: 'PingFang-SC',
    cursor: 'pointer',
  },
  menuBarItemBaseline: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    width: '0',
    height: '4px',
    transform: 'translateX(-25px)',
    backgroundColor: '#00F0FF',
  },
  menuBarActiveItemBaseline: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    width: '50px',
    height: '4px',
    transform: 'translateX(-25px)',
    backgroundColor: '#00F0FF',
  },
  menuListContainer: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: '0 15px',
  },
  menuListItem: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '15px 0 15px 12px',
    color: theme.palette.text.menuBar,
    fontSize: '16px',
    fontFamily: 'PingFang-SC',
    cursor: 'pointer',
    borderBottom: '1px solid ' + theme.palette.border.menuList,
  },
  menuListActiveItem: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '15px 0 15px 12px',
    color: '#00F0FF',
    fontSize: '16px',
    fontFamily: 'PingFang-SC',
    cursor: 'pointer',
    borderBottom: '1px solid ' + theme.palette.border.menuList,
  },
  menuListItemBaseline: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '0',
    height: '30px',
    transform: 'translateX(-15px) translateY(-15px)',
    backgroundColor: '#00F0FF',
  },
  menuListActiveItemBaseline: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '4px',
    height: '30px',
    transform: 'translateX(-15px) translateY(-15px)',
    backgroundColor: '#00F0FF',
  },
  iconButton: {
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '8px 10px',
    borderRadius: '6px',
    color: theme.palette.text.primary,
    fontSize: '12px',
    backgroundColor: theme.palette.background.iconButton,
    cursor: 'pointer',
  },
  buyButton: {
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'center',
    lineHeight: '36px',
    padding: '0px 21px', 
    borderRadius: '6px',
    color: '#010029',
    fontSize: '17px',
    backgroundColor: '#00F0FF',
    cursor: 'pointer',
    fontWeight:  'bold',
    '@media (max-width: 796px)': {
      fontSize: '13px',
      padding: '0px 10px',
    },
  },
  iconButtonText: {
    marginLeft: '6px',
    fontFamily: 'PingFang-SC',
    color: theme.palette.text.iconButton,
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.card,
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: '240px',
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    height: '100vh',
    top: '0',
    left: '0',
    right: 'auto',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    ...transition,
  },
});

export default styles;
