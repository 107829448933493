const styles = (theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  sectionTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0',
    '@media (max-width: 769px)': {
      padding: '20px 0',
    },
  },
  sectionTitleLeft: {
    fontFamily: 'AlibabaPuHuiTiB',
    fontSize: '68px',
    fontWeight: 'bold',
    color: '#3861FB',
    '@media (max-width: 769px)': {
      fontSize: '20px',
    },
  },
  sectionTitleRight: {
    fontFamily: 'AlibabaPuHuiTiB',
    fontSize: '68px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    '@media (max-width: 769px)': {
      fontSize: '20px',
    },
  },
  sectionTitleDescription: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    maxWidth: '720px',
    textAlign: 'center',
    padding: '5px',
    fontFamily: 'PingFang-SC',
    fontSize: '22px',
    fontWeight: 'normal',
    color: theme.palette.text.description,
    lineHeight: '48px',
    letterSpacing: '1px',
    boxSizing: 'border-box',
    '@media (max-width: 769px)': {
      padding: 0,
      fontSize: '16px',
      lineHeight: '28px',
    },
  },
  menuBarAppContainer: {
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },
});

export default styles;
