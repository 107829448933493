import { container } from 'assets/jss/material-kit-pro-react.js';

export default (theme) => ({
  roadMap: {
    width: '100%',
    background: '#010029',
    color: theme.palette.text.primary
  },
  container: {
    ...container,
  },
  roadMapTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '90px',
    marginBottom: '90px',
    '@media (max-width: 769px)': {
    paddingTop: '40px',
    marginBottom: '40px',
    },
  },
  roadMapTitleText: {
    flexShrink: 0,
    fontFamily: 'PingFang-SC',
    fontSize: '46px',
    fontWeight: 'bold',
    color: theme.palette.text.iconButton,
    margin: '0 60px',
    '@media (max-width: 769px)': {
      fontSize: '18px',
      margin: '0 20px',
    },
  },
  roadMapDividerLeft: {
    display: 'flex',
    width: '30%',
    height: '4px',
    backgroundImage: `linear-gradient(to right, transparent 0%, ${theme.palette.background.gradientEnd} 100%)`,
    borderRadius: '2px',
  },
  roadMapDividerRight: {
    display: 'flex',
    width: '30%',
    height: '4px',
    backgroundImage: `linear-gradient(to left, transparent 0%, ${theme.palette.background.gradientEnd} 100%)`,
    borderRadius: '2px',
  },
  roadMapItemTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
  },
  roadMapItemCircle: {
    display: 'flex',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '20px',
  },
  roadMapItemText: {
    fontFamily: 'PingFang-SC',
    fontSize: '24px',
    fontWeight: 'bold',
    color: theme.palette.text.iconButton,
  },
  timeLineContainer: {
    width: '100%',
    height: 'auto',
  },
  timeLineItem: {
    position: 'relative',
    padding: '15px 0 10px 25px',
    boxSizing: 'border-box',
    backgroundColor: 'transparent!important',
  },
  timeLineDot: {
    position: 'absolute',
    top: 0,
    left: '5.5px',
    width: 0,
    height: '100%',
    borderLeft: '1px dotted #cccedd',
    transform: 'translateY(30px)',
  },
  light: {
    marginLeft: '-15px',
    marginRight: '10px'
  },
  timeLineSpot: {
    position: 'absolute',
    top: '25px',
    left: '1px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: 'linear-gradient(180deg, #FD1CA5 0%, #811ABF 43.75%, #4719CB 64.06%, #6455EA 100%);',
  },
  timeLineSpotold: {
    position: 'absolute',
    top: '25px',
    left: '1px',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: '#fff',
  
  },
  timeLineText: {
    fontFamily: 'PingFang-SC',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '30px',
    color: theme.palette.text.description,
  },
});
