import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import styles from './index.style';

const useStyles = makeStyles(styles);

const TIME_LINE_TEXT_MAP = [
  ['TL-1-1', 'TL-1-2', 'TL-1-3', 'TL-1-4'],
  ['TL-2-1', 'TL-2-2', 'TL-2-3'],
  [
    'TL-3-1',
    'TL-3-2',
    'TL-3-3',
    'TL-3-4',
    'TL-3-5',
    'TL-3-6',
    'TL-3-7',
    'TL-3-8',
    'TL-3-9'
  ],
  ['TL-4-1', 'TL-4-2', 'TL-4-3', 'TL-4-4', 'TL-4-5', 'TL-4-6', 'TL-4-7'],
];
const PANEL_MAP = ['panel1', 'panel2', 'panel3', 'panel4']

const TimeLineComponent = ({ data , color}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const len = data?.length;

  if (!len || len === 0) {
    return null;
  }

  return (
    <div className={classes.timeLineContainer}>
      {data.map((item, i) => (
        <div key={item} className={classes.timeLineItem}>
          {i !== (len - 1) && <span className={classes.timeLineDot}></span>}
          <span className={color == 2 ?classes.timeLineSpotold: classes.timeLineSpot}></span>
          <span className={classes.timeLineText}>{t(item)}</span>
        </div>
      ))}
    </div>
  );
}

const RoadMapAccordionItemTop = ({ color, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.roadMapItemTitle} style={{ marginBottom: 0 }}>
      <span
        className={classes.roadMapItemCircle}
        style={{ backgroundColor: color }}
      ></span>
      <span className={classes.roadMapItemText} style={{ fontSize: '16px' }}>{title}</span>
    </div>
  );
};

const RoadMapItemTop = ({ color, title, active }) => {
  const classes = useStyles();
  return (
    <div className={classes.roadMapItemTitle}>
      {/* <span
        className={classes.roadMapItemCircle}
        style={{ backgroundColor: color }}
      ></span> */}
       <img src={require(`assets/img/about/light${active == 2?'2':''}.png`)} className={classes.light}></img>
      <span className={classes.roadMapItemText}>{title}</span>
    </div>
  );
}

export default () => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.roadMap}>
      <div className={classes.roadMapTitle}>
        {/* <span className={classes.roadMapDividerLeft}></span> */}
        <span className={classes.roadMapTitleText}>{t('RoadMap')}</span>
        {/* <span className={classes.roadMapDividerRight}></span> */}
      </div>
      <Hidden smDown>
        <Grid container className={classes.container}>
          <Grid
            item
            xs={12}
            md={3}
            flexDirection="column"
            style={{ padding: '0 16px' }}
          >
            <RoadMapItemTop title="2020 Q4" color="#5342f5" active="1"/>
            <TimeLineComponent data={TIME_LINE_TEXT_MAP[0]} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            flexDirection="column"
            style={{ padding: '0 16px'}}
          >
            <RoadMapItemTop title="2021 Q1" color="#6bfafb" active="1"/>
            <TimeLineComponent data={TIME_LINE_TEXT_MAP[1]} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            flexDirection="column"
            style={{ padding: '0 16px' }}
          >
            <RoadMapItemTop title="2021 Q2" color="#6bfafb" active="1"/>
            <TimeLineComponent data={TIME_LINE_TEXT_MAP[2]} />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            flexDirection="column"
            style={{ padding: '0 16px' }}
          >
            <RoadMapItemTop title="2021 Q3-Q4" color="#ec564d" active="2"/>
            <TimeLineComponent data={TIME_LINE_TEXT_MAP[3]} color="2"/>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.roadMapAccordion}>
          <Accordion
            expanded={expanded === PANEL_MAP[0]}
            onChange={handleChange(PANEL_MAP[0])}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <RoadMapAccordionItemTop title="2020 Q4" color="#5342f5" />
            </AccordionSummary>
            <AccordionDetails>
              <TimeLineComponent data={TIME_LINE_TEXT_MAP[0]} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === PANEL_MAP[1]}
            onChange={handleChange(PANEL_MAP[1])}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <RoadMapAccordionItemTop title="2021 Q1" color="#6bfafb" />
            </AccordionSummary>
            <AccordionDetails>
              <TimeLineComponent data={TIME_LINE_TEXT_MAP[1]} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === PANEL_MAP[2]}
            onChange={handleChange(PANEL_MAP[2])}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <RoadMapAccordionItemTop title="2021 Q2" color="#f0b33b" />
            </AccordionSummary>
            <AccordionDetails>
              <TimeLineComponent data={TIME_LINE_TEXT_MAP[2]} color="2"/>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === PANEL_MAP[3]}
            onChange={handleChange(PANEL_MAP[3])}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <RoadMapAccordionItemTop title="2021 Q3-Q4" color="#ec564d" />
            </AccordionSummary>
            <AccordionDetails>
              <TimeLineComponent data={TIME_LINE_TEXT_MAP[3]} color="2"/>
            </AccordionDetails>
          </Accordion>
        </div>
      </Hidden>
      <div className={classes.roadMapTitle}>
        {/* <span className={classes.roadMapDividerLeft}></span> */}
        <span className={classes.roadMapTitleText}>{}</span>
        {/* <span className={classes.roadMapDividerRight}></span> */}
      </div>
    </div>
  );
}
