export const bscPools = [
  {
    id: 'bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/bifi-maxi',
    earnedToken: 'mooBIFI',
    earnedTokenAddress: '0xf7069e41C57EcC5F122093811d8c75bdB5f7c14e',
    earnContractAddress: '0xf7069e41C57EcC5F122093811d8c75bdB5f7c14e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy',
    assets: ['BIFI'],
    callFee: 0.5,
  },

  {
    id: '1inch-1inch',
    logo: 'single-assets/INCH.png',
    name: '1INCH',
    token: '1INCH',
    tokenDescription: '1Inch',
    tokenAddress: '0x111111111117dC0aa78b770fA6A738034120C302',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moo1INCH1INCH',
    earnedTokenAddress: '0xBa53AF4C2f1649F82e8070FB306DDBF2771A1950',
    earnContractAddress: '0xBa53AF4C2f1649F82e8070FB306DDBF2771A1950',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: '1INCH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: '1Inch',
    assets: ['1INCH'],
    callFee: 0.5,
    launchpool: 'moo_1inch-hps',
  },

  {
    id: 'auto-wbnb',
    logo: 'single-assets/WBNB.svg',
    name: 'WBNB',
    token: 'WBNB',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoWbnbFixed',
    earnedTokenAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
    earnContractAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BNB'],
    callFee: 0.5,
    addLiquidityUrl: 'https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    launchpool: 'moo_auto_wbnb-naut',
  },

  {
    id: 'auto-cake-bnb',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCAKE-BNB',
    earnedTokenAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
    earnContractAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-cake-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['CAKE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    launchpool: 'moo_cake_bnb-space',
  },

  {
    id: 'hps-hps',
    logo: 'single-assets/HPS.png',
    name: 'HPS',
    token: 'HPS',
    tokenDescription: 'BillionHappiness',
    tokenAddress: '0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooBillionHPS',
    earnedTokenAddress: '0xAC8E64BE515fAe8c6026fb22954d4D1375938Bb5',
    earnContractAddress: '0xAC8E64BE515fAe8c6026fb22954d4D1375938Bb5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'HPS',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BillionHappiness',
    assets: ['HPS'],
    callFee: 0.5,
  },
  {
    id: 'hps-hps-bnb',
    logo: 'single-assets/HPS.png',
    name: 'HPS-BNB LP',
    token: 'HPS-BNB LP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0xcaC3b7DE7D5c44E8E1048241C7dE29a61b5C3E7d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooBillionHPS-BNB',
    earnedTokenAddress: '0x17cB1Ddb71ca34958e54f8152f0913C25bC50DF6',
    earnContractAddress: '0x17cB1Ddb71ca34958e54f8152f0913C25bC50DF6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'hps-hps-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BillionHappiness',
    assets: ['HPS', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/BNB/0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
  },

  {
    id: 'ellipsis-eps-bnb',
    logo: 'uncategorized/EPS.png',
    name: 'EPS-BNB LP',
    token: 'EPS-BNB LP',
    tokenDescription: 'Ellipsis',
    tokenAddress: '0xf9045866e7b372DeF1EFf3712CE55FAc1A98dAF0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEllipsisEPS-BNB',
    earnedTokenAddress: '0xD467af8C117AcaF7B2725a6d35Dc5A471A683055',
    earnContractAddress: '0xD467af8C117AcaF7B2725a6d35Dc5A471A683055',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ellipsis-eps-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ellipsis',
    assets: ['EPS', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl: 'https://exchange.pancakeswap.finance/#/add/BNB/0xA7f552078dcC247C2684336020c03648500C6d9F',
  },
  {
    id: 'ellipsis-3eps',
    logo: 'uncategorized/EPS3.png',
    name: 'BUSD/USDT/USDC',
    token: 'EPS 3Pool',
    tokenDescription: 'Ellipsis',
    tokenAddress: '0xaF4dE8E872131AE328Ce21D909C74705d3Aaf452',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooEllipsis3Pool',
    earnedTokenAddress: '0xE563c046147b4dF98bfCD3d00Dc54511F0c3b752',
    earnContractAddress: '0xE563c046147b4dF98bfCD3d00Dc54511F0c3b752',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ellipsis-3eps',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Ellipsis',
    assets: ['USDT', 'BUSD', 'USDC', '3EPS'],
    callFee: 0.5,
    addLiquidityUrl: 'https://ellipsis.finance/3pool/deposit',
  },

  {
    id: 'alpaca-salpaca',
    logo: 'single-assets/sALPACA.svg',
    name: 'sALPACA',
    token: 'sALPACA',
    tokenDescription: 'Alpaca',
    tokenAddress: '0x6F695Bd5FFD25149176629f8491A5099426Ce7a7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAlpacaSALPACA',
    earnedTokenAddress: '0xCd1C6adca69071F61EfE5aaa0BB63cA3419D5088',
    earnContractAddress: '0xCd1C6adca69071F61EfE5aaa0BB63cA3419D5088',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ALPACA',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Alpaca',
    assets: ['sALPACA'],
    callFee: 0.5,
  },

  {
    id: 'naut-naut-bnb',
    logo: 'degens/NAUT.png',
    name: 'NAUT-BNB LP',
    token: 'NAUT-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x288EA5437c7aaD045a393cee2F41E548df24d1C8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooNautNAUT-BNB',
    earnedTokenAddress: '0x693402b80b073b872589433e6fa1b08665E12025',
    earnContractAddress: '0x693402b80b073b872589433e6fa1b08665E12025',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'naut-naut-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['NAUT', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
  },

  {
    id: 'cake-zil-bnb',
    logo: 'bnb-pairs/ZIL-BNB.svg',
    name: 'ZIL-BNB LP',
    token: 'ZIL-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xc746337b5F800a0e19eD4eB3bda03FF1401B8167',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeZIL-BNB',
    earnedTokenAddress: '0xdFD00D26f698d036EA26900614793829D119C0Ca',
    earnContractAddress: '0xdFD00D26f698d036EA26900614793829D119C0Ca',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-zil-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['ZIL', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xb86abcb37c3a4b64f74f59301aff131a1becc787',
  },

  {
    id: 'cake-lto-bnb',
    logo: 'bnb-pairs/LTO-BNB.svg',
    name: 'LTO-BNB LP',
    token: 'LTO-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x85644FcD00C401E1A0a0a10d2aE6Bbe04A73e4ab',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeLTO-BNB',
    earnedTokenAddress: '0x37A36B817f372A9F1bDb2Ee59bA1D2c599B2AE77',
    earnContractAddress: '0x37A36B817f372A9F1bDb2Ee59bA1D2c599B2AE77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-lto-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['LTO', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
  },

  {
    id: 'cake-pcws-bnb',
    logo: 'bnb-pairs/pCWS-BNB.svg',
    name: 'pCWS-BNB LP',
    token: 'pCWS-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x889E81d25bFfbA437b2a5d3e0E4FC58a0E2749c5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakepCWS-BNB',
    earnedTokenAddress: '0x80736F3dd7B2c17ea08C452D3140a7daa461f187',
    earnContractAddress: '0x80736F3dd7B2c17ea08C452D3140a7daa461f187',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-pcws-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['pCWS', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
  },

  {
    id: 'cake-trade-bnb',
    logo: 'bnb-pairs/TRADE-BNB.svg',
    name: 'TRADE-BNB LP',
    token: 'TRADE-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x2562f94E90dE6D9eb4fB6B3b8Eab56b15Aa4FC72',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTRADE-BNB',
    earnedTokenAddress: '0x3F3601229F6fE7D9f3FA8554f0C3Ca4CaE8043B4',
    earnContractAddress: '0x3F3601229F6fE7D9f3FA8554f0C3Ca4CaE8043B4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-trade-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['TRADE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x7af173f350d916358af3e218bdf2178494beb748',
  },

  {
    id: 'alpaca-alpaca-bnb',
    logo: 'bnb-pairs/ALPACA-BNB.png',
    name: 'ALPACA-BNB LP',
    token: 'ALPACA-BNB LP',
    tokenDescription: 'Alpaca',
    tokenAddress: '0xF3CE6Aac24980E6B657926dfC79502Ae414d3083',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAlpacaALPACA-BNB',
    earnedTokenAddress: '0xB4CF4b0d79AF3A5627D5595483e0df9620B7d236',
    earnContractAddress: '0xB4CF4b0d79AF3A5627D5595483e0df9620B7d236',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'alpaca-alpaca-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Alpaca',
    assets: ['ALPACA', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
  },

  {
    id: 'space-space-bnb',
    logo: 'degens/SPACE-BNB.png',
    name: 'SPACE-BNB LP',
    token: 'SPACE-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x78D9a0c50F6484291b4122f61888Bb9EE71879Ff',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSPACE-BNB',
    earnedTokenAddress: '0xbC943C4C00E837e65Cf76BcD8Df8C365FB44Dd6f',
    earnContractAddress: '0xbC943C4C00E837e65Cf76BcD8Df8C365FB44Dd6f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'space-space-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['SPACE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0abd3E3502c15ec252f90F64341cbA74a24fba06',
  },
  {
    id: 'space-space-busd',
    logo: 'degens/SPACE-BUSD.png',
    name: 'SPACE-BUSD LP',
    token: 'SPACE-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x6B936c5C1FD7De08E03684b0588A87DBD8ce6b63',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSPACE-BUSD',
    earnedTokenAddress: '0xd71A3edcdAe126a30960df40CB5247d87980Bf08',
    earnContractAddress: '0xd71A3edcdAe126a30960df40CB5247d87980Bf08',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'space-space-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['SPACE', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x0abd3E3502c15ec252f90F64341cbA74a24fba06',
  },

  {
    id: 'cake-bbadger-btcb',
    logo: 'uncategorized/BADGER-BTCB.svg',
    name: 'bBADGER-BTCB LP',
    token: 'bBADGER-BTCB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x10F461CEAC7A17F59e249954Db0784d42EfF5DB5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBADGER-BNB',
    earnedTokenAddress: '0x9fBa2fc7505CE6801ADCF23146310187CecfcE47',
    earnContractAddress: '0x9fBa2fc7505CE6801ADCF23146310187CecfcE47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bbadger-btcb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['bBADGER', 'BTC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x1f7216fdb338247512ec99715587bb97bbf96eae',
  },

  {
    id: 'cake-bdigg-btcb',
    logo: 'uncategorized/BDIGG-BTCB.svg',
    name: 'bDIGG-BTCB LP',
    token: 'bDIGG-BTCB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xE1E33459505bB3763843a426F7Fd9933418184ae',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBDIGG-BNB',
    earnedTokenAddress: '0x2c1a0950aC426f920f260C9647BCD3AFD0482f4e',
    earnContractAddress: '0x2c1a0950aC426f920f260C9647BCD3AFD0482f4e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdigg-btcb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['bDIGG', 'BTC'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  },

  {
    id: 'pumpy-pmp-bnb',
    logo: 'single-assets/PMP.svg',
    name: 'PMP-BNB LP',
    token: 'PMP-BNB LP',
    tokenDescription: 'Pancake (Pumpy)',
    tokenAddress: '0x18CCA70DDa1699148cB37941d75ccF4847BEa188',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPumpyPMP-BNB',
    earnedTokenAddress: '0xE929d65cBf543cC3cA6b18Fe7872fccC6abBf480',
    earnContractAddress: '0xE929d65cBf543cC3cA6b18Fe7872fccC6abBf480',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'pumpy-pmp-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pumpy',
    assets: ['PMP', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x8d4FBB3AC63bf33851dCE80D63613Df1A515BC00',
  },

  {
    id: 'cake-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.svg',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xd132D2C24F29EE8ABb64a66559d1b7aa627Bd7fD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBIFI-BNB',
    earnedTokenAddress: '0x3B5332A476AbCdb80Cde6645e9e5563435e97772',
    earnContractAddress: '0x3B5332A476AbCdb80Cde6645e9e5563435e97772',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bifi-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BIFI', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  },

  {
    id: 'jul-twt-bnb',
    logo: 'bnb-pairs/JTWT-BNB.svg',
    name: 'TWT-BNB LP',
    token: 'TWT-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x14aac5ba46418d625db4f506988e3ee5a0963887',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulTWT-BNB',
    earnedTokenAddress: '0x7091E04422d1D9Be48C211cf2F818a7E88EFd736',
    earnContractAddress: '0x7091E04422d1D9Be48C211cf2F818a7E88EFd736',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-twt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['TWT', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/BNB/0x4B0F1812e5Df2A09796481Ff14017e6005508003',
  },

  {
    id: 'jul-vidt-bnb',
    logo: 'bnb-pairs/VIDT-BNB.svg',
    name: 'VIDT-BNB LP',
    token: 'VIDT-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x50b68945a990684aedbfa46391988b4609923d48',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulVIDT-BNB',
    earnedTokenAddress: '0x621Ab9A2EF4256A43dEFeB37F0a1eACb276BbF97',
    earnContractAddress: '0x621Ab9A2EF4256A43dEFeB37F0a1eACb276BbF97',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-vidt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['VIDT', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/BNB/0x3f515f0a8e93F2E2f891ceeB3Db4e62e202d7110',
  },

  {
    id: 'blizzard-blzd-bnb',
    logo: 'degens/BLZD-BNB.png',
    name: 'BLZD-BNB LP',
    token: 'BLZD-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x27A5a5c1fF96447F2a0c4baDcF26E7c65C040E3C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardBLZD-BNB',
    earnedTokenAddress: '0x57e6f78a7e644b8532d8c4f3A354c921709Aa897',
    earnContractAddress: '0x57e6f78a7e644b8532d8c4f3A354c921709Aa897',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-blzd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Blizzard',
    assets: ['BLZD', 'BNB'],
    callFee: 1,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
  },

  {
    id: 'blizzard-blzd-busd',
    logo: 'degens/BLZD-BUSD.png',
    name: 'BLZD-BUSD LP',
    token: 'BLZD-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xE9C53B5Ab0C9cDBd72A03151a628863C28c55A6A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardBLZD-BUSD',
    earnedTokenAddress: '0x1EdfEc6849C54Ee9187539516C6a67ADAcBf9704',
    earnContractAddress: '0x1EdfEc6849C54Ee9187539516C6a67ADAcBf9704',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'blizzard-blzd-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Blizzard',
    assets: ['BLZD', 'BUSD'],
    callFee: 1,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
  },

  {
    id: 'blizzard-blzd',
    logo: 'degens/BLZD.png',
    name: 'BLZD',
    token: 'BLZD',
    tokenDescription: 'Blizzard',
    tokenAddress: '0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBlizzardBLZD',
    earnedTokenAddress: '0x580b899D8d856DFe3da7895466F779F1A509D2Fd',
    earnContractAddress: '0x580b899D8d856DFe3da7895466F779F1A509D2Fd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BLZD',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Blizzard',
    assets: ['BLZD'],
    callFee: 0.5,
  },

  {
    id: 'venus-eth',
    logo: 'single-assets/ETH.png',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Venus',
    tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusETH',
    earnedTokenAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
    earnContractAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['ETH'],
    callFee: 1,
  },

  {
    id: 'com-com-bnb',
    logo: 'bnb-pairs/COM-BNB.png',
    name: 'COM-BNB LP',
    token: 'COM-BNB LP',
    tokenDescription: 'Complus',
    tokenAddress: '0x7FEAb71e859843D56358069B1374E266F7c0a75f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooComplusCOM-BNB',
    earnedTokenAddress: '0x0DA3EB14c8d07a87A366D029d7f2341Ac0eca775',
    earnContractAddress: '0x0DA3EB14c8d07a87A366D029d7f2341Ac0eca775',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'com-com-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Complus',
    assets: ['COM', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://bscdex.complus.exchange/#/add/BNB/0x7fa892544D49598460B821De4D99E8c28b1Decaa',
  },

  {
    id: 'belt-venus-blp',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    name: 'BELT VENUS BLP',
    token: 'VENUS BLP',
    tokenDescription: 'Belt',
    tokenAddress: '0x86aFa7ff694Ab8C985b79733745662760e454169',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltVenusBLP',
    earnedTokenAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
    earnContractAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-venus-blp',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Belt',
    assets: ['USDT', 'BUSD', 'USDC', 'DAI', 'VENUS BLP'],
    callFee: 0.5,
    addLiquidityUrl: 'https://belt.fi/',
  },

  {
    id: 'cake-dusk-bnb',
    logo: 'bnb-pairs/DUSK-BNB.svg',
    name: 'DUSK-BNB LP',
    token: 'DUSK-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xB7918560273FD56e50E9c215CC0DFE8D764C36C5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDUSK-BNB',
    earnedTokenAddress: '0x4BeE93b29e1b3fd90bb3e6EdE9539Fe92E9836Cd',
    earnContractAddress: '0x4BeE93b29e1b3fd90bb3e6EdE9539Fe92E9836Cd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dusk-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['DUSK', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
  },

  {
    id: 'cake-cos-bnb',
    logo: 'bnb-pairs/COS-BNB.svg',
    name: 'COS-BNB LP',
    token: 'COS-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x7b1e440240B220244761C9D9A3B07fbA1995BD84',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeCOS-BNB',
    earnedTokenAddress: '0x61B51dc9AEb46403628930ef5F527168941E639b',
    earnContractAddress: '0x61B51dc9AEb46403628930ef5F527168941E639b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-cos-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['COS', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x96Dd399F9c3AFda1F194182F71600F1B65946501',
  },

  {
    id: 'cake-txl-busd',
    logo: 'busd-pairs/TXL-BUSD.svg',
    name: 'TXL-BUSD LP',
    token: 'TXL-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xD20E0BcCa8B29409bf5726CB24DD779Fe337020e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTXL-BUSD',
    earnedTokenAddress: '0x8AA0635A4719e665A08BaDe38b13F4a6d278165A',
    earnContractAddress: '0x8AA0635A4719e665A08BaDe38b13F4a6d278165A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-txl-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['TXL', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5',
  },

  {
    id: 'squirrel-nuts-bnb',
    logo: 'single-assets/NUTS.png',
    name: 'NUTS-BNB LP',
    token: 'NUTS-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x034C9E6B08c09a6144e0D0E52161338fB105e656',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSquirrelNUTS-BNB',
    earnedTokenAddress: '0xB1C9A27394dBa451Bfc4a5dce8Fc829f8a3E9278',
    earnContractAddress: '0xB1C9A27394dBa451Bfc4a5dce8Fc829f8a3E9278',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'squirrel-nuts-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Squirrel',
    assets: ['NUTS', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
  },

  {
    id: 'squirrel-nuts',
    logo: 'single-assets/NUTS.png',
    name: 'NUTS',
    token: 'NUTS',
    tokenDescription: 'Squirrel',
    tokenAddress: '0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSquirrelNUTS',
    earnedTokenAddress: '0x2D40beB0122aBcAD0E6b49934d47adac6Dddd97B',
    earnContractAddress: '0x2D40beB0122aBcAD0E6b49934d47adac6Dddd97B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'NUTS',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Squirrel',
    assets: ['NUTS'],
    callFee: 0.5,
  },

  {
    id: 'bakery-bat-bake',
    logo: 'uncategorized/BAT-BAKE.svg',
    name: 'BAT-BAKE LP',
    token: 'BAT-BAKE LP',
    tokenDescription: 'Bakery',
    tokenAddress: '0x675Ec26cF1c99A5cD227658f5d0e2fAcbbf3Dcf7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAT-BAKE',
    earnedTokenAddress: '0x92E586d7dB14483C103c2e0FE6A596F8b55DA752',
    earnContractAddress: '0x92E586d7dB14483C103c2e0FE6A596F8b55DA752',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-bat-bake',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAT', 'BAKE'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/0x101d82428437127bf1608f699cd651e6abf9766e/0xe02df9e3e622debdd69fb838bb799e3f168902c5',
  },

  {
    id: 'memefarm-ape-bnb',
    logo: 'degens/ape-bnb.png',
    name: 'APE-BNB LP',
    token: 'APE-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x73E83B4Cc6c03e60f86D816C5ED3050136b7ab78',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMemefarmAPE-BNB',
    earnedTokenAddress: '0xbA67606DFE07387D20d9A324eF91732954688D77',
    earnContractAddress: '0xbA67606DFE07387D20d9A324eF91732954688D77',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'memefarm-ape-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'MemeFarm',
    assets: ['APE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/ETH/0xa9eA4b786ee5b7A733c035564Bfd9341A4c9FC1e',
  },
  {
    id: 'memefarm-mfrm-bnb',
    logo: 'degens/mfrm-bnb.png',
    name: 'MFRM-BNB LP',
    token: 'MFRM-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x9E6463BB3b6ff6A768290A4eFC452dC92F2CfA2A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMemefarmMFRM-BNB',
    earnedTokenAddress: '0x94d75413A29287Dde5766E5595dc61e668965170',
    earnContractAddress: '0x94d75413A29287Dde5766E5595dc61e668965170',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'memefarm-mfrm-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'MemeFarm',
    assets: ['MFRM', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/ETH/0x206340f3361404910F45cA0893980EF3f9b418ea',
  },

  {
    id: 'slime-slime-busd',
    logo: 'degens/slime-busd.png',
    name: 'SLME-BUSD LP',
    token: 'SLME-BUSD LP',
    tokenDescription: 'SlimeFinance',
    tokenAddress: '0xfbd0B87f4132E5A14aA85C21476738C0C13Fd06c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSlimeSLIME-BUSD',
    earnedTokenAddress: '0xd7987DB14509FACb9CC44c0b82CC86c188f5cEEF',
    earnContractAddress: '0xd7987DB14509FACb9CC44c0b82CC86c188f5cEEF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'slime-slime-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SlimeFinance',
    assets: ['SLME', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://slime.finance/swap-slimeeees#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
  },
  {
    id: 'slime-slime-bnb',
    logo: 'degens/slime-bnb.png',
    name: 'SLME-BNB LP',
    token: 'SLME-BNB LP',
    tokenDescription: 'SlimeFinance',
    tokenAddress: '0xcb645714520080EF4E65De3254d61356262F0818',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSlimeSLIME-BNB',
    earnedTokenAddress: '0x62d7eC40A1a3f0AB5D1fCC0D8bd3dCb015aFe6a3',
    earnContractAddress: '0x62d7eC40A1a3f0AB5D1fCC0D8bd3dCb015aFe6a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'slime-slime-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SlimeFinance',
    assets: ['SLME', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://slime.finance/swap-slimeeees#/add/ETH/0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
  },

  {
    id: 'cake-alice-bnb',
    logo: 'bnb-pairs/ALICE-BNB.svg',
    name: 'ALICE-BNB LP',
    token: 'ALICE-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xe022baa3E5E87658f789c9132B10d7425Fd3a389',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeALICE-BNB',
    earnedTokenAddress: '0x0C098a54FeE7EC15a59587E69cD340BBd9d22077',
    earnContractAddress: '0x0C098a54FeE7EC15a59587E69cD340BBd9d22077',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-alice-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['ALICE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/ETH/0xAC51066d7bEC65Dc4589368da368b212745d63E8',
  },

  {
    id: 'auto-beth-eth',
    logo: 'uncategorized/BETH-ETH.svg',
    name: 'BETH-ETH LP',
    token: 'BETH-ETH LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x99d865Ed50D2C32c1493896810FA386c1Ce81D91',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBETH-ETH',
    earnedTokenAddress: '0xF3C1EB01E40c47fd32D0397e56569809aae0e9c7',
    earnContractAddress: '0xF3C1EB01E40c47fd32D0397e56569809aae0e9c7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-beth-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BETH', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
  },

  {
    id: 'belt-belt-bnb',
    logo: 'bnb-pairs/BELT-BNB-BELT.png',
    name: 'BELT-BNB LP',
    token: 'BELT-BNB LP',
    tokenDescription: 'Pancake (Belt)',
    tokenAddress: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltBeltBNB',
    earnedTokenAddress: '0xfc01F7a17eEb5DABc97312E13954444b00217621',
    earnContractAddress: '0xfc01F7a17eEb5DABc97312E13954444b00217621',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-belt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Belt',
    assets: ['BELT', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/ETH/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
  },

  {
    id: 'cake-nuls-busd',
    logo: 'busd-pairs/NULS-BUSD.svg',
    name: 'NULS-BUSD LP',
    token: 'NULS-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xCA01F5D89d5b1d24ca5D6Ecc856D21e8a61DAFCc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeNULS-BUSD',
    earnedTokenAddress: '0x96F66Dcf58fe5D276d64AC43095E2910AD4DBfD1',
    earnContractAddress: '0x96F66Dcf58fe5D276d64AC43095E2910AD4DBfD1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-nuls-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['NULS', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'banana-banana',
    logo: 'degens/BANANA.svg',
    name: 'BANANA',
    token: 'BANANA',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBANANA',
    earnedTokenAddress: '0xD307e7CC6a302046b7D91D83aa4B8324cFB7a786',
    earnContractAddress: '0xD307e7CC6a302046b7D91D83aa4B8324cFB7a786',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BANANA',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA'],
    callFee: 0.5,
  },

  {
    id: 'cake-belt-bnb',
    logo: 'bnb-pairs/BELT-BNB.svg',
    name: 'BELT-BNB LP',
    token: 'BELT-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBELT-BNB',
    earnedTokenAddress: '0xee43C2D11Bc6DF95dcECd9be05ae648863D6f9dC',
    earnContractAddress: '0xee43C2D11Bc6DF95dcECd9be05ae648863D6f9dC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-belt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BELT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-ramp-busd',
    logo: 'busd-pairs/RAMP-BUSD.svg',
    name: 'RAMP-BUSD LP',
    token: 'RAMP-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xbf36959939982d0d34b37fb3f3425da9676c13a3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeRAMP-BUSD',
    earnedTokenAddress: '0x32FDaa004C1d9cFCf9B8044D6015DD7bb3a7Ec5a',
    earnContractAddress: '0x32FDaa004C1d9cFCf9B8044D6015DD7bb3a7Ec5a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ramp-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['RAMP', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'jul-btcb-bnb',
    logo: 'bnb-pairs/JBTCB-BNB.svg',
    name: 'BTCB-BNB LP',
    token: 'BTCB-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x3f3d9e0803b19dd9002c8184ae3c1c9d09720682',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulBTCB-BNB',
    earnedTokenAddress: '0xFa7767Cf402a6D198cC136FED32550ABA70C3e02',
    earnContractAddress: '0xFa7767Cf402a6D198cC136FED32550ABA70C3e02',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-btcb-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['BTC', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'jul-uni-bnb',
    logo: 'bnb-pairs/JUNI-BNB.svg',
    name: 'UNI-BNB LP',
    token: 'UNI-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x1a5F04e8E20dd15C219C39cc2a989F64B3307779',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulUNI-BNB',
    earnedTokenAddress: '0xd9fe7Ff89C5303E439a14a5155F7F48E34F28518',
    earnContractAddress: '0xd9fe7Ff89C5303E439a14a5155F7F48E34F28518',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-uni-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['UNI', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'jul-dot-bnb',
    logo: 'bnb-pairs/JDOT-BNB.svg',
    name: 'DOT-BNB LP',
    token: 'DOT-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0xdA0FEa17F15aD56c1ae218Cf85173ff26862942B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulDOT-BNB',
    earnedTokenAddress: '0xa640E017Fc01dD39F6b7B07b0460B04E218c0a01',
    earnContractAddress: '0xa640E017Fc01dD39F6b7B07b0460B04E218c0a01',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-dot-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['DOT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'jul-xvs-bnb',
    logo: 'bnb-pairs/JXVS-BNB.svg',
    name: 'XVS-BNB LP',
    token: 'XVS-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x6e15E1989fC3cFfAB40cA21F7E9ad45AAb72cFfc',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulXVS-BNB',
    earnedTokenAddress: '0x48cc86214C58d7EaA78C100156c55DD45A676Ed1',
    earnContractAddress: '0x48cc86214C58d7EaA78C100156c55DD45A676Ed1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-xvs-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['XVS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'auto-auto-bnb',
    logo: 'bnb-pairs/AUTO-BNB.png',
    name: 'AUTO-BNB LP',
    token: 'AUTO-BNB LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x4d0228EBEB39f6d2f29bA528e2d15Fc9121Ead56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoAUTO-BNB',
    earnedTokenAddress: '0x6Cb6D451e7E7ca5d5F73715D6B5dfc55EfbC1f82',
    earnContractAddress: '0x6Cb6D451e7E7ca5d5F73715D6B5dfc55EfbC1f82',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-auto-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['AUTO', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xa184088a740c695E156F91f5cC086a06bb78b827',
  },

  {
    id: 'auto-cake',
    logo: 'single-assets/SMART.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCakeFixed',
    earnedTokenAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
    earnContractAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['CAKE'],
    callFee: 0.5,
  },

  {
    id: 'auto-comp-eth',
    logo: 'uncategorized/COMP-ETH.svg',
    name: 'COMP-ETH LP',
    token: 'COMP-ETH LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x0392957571F28037607C14832D16f8B653eDd472',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCOMP-ETH',
    earnedTokenAddress: '0x907b3848593df20243eCb5D954b06a028092b0D6',
    earnContractAddress: '0x907b3848593df20243eCb5D954b06a028092b0D6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-comp-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['COMP', 'ETH'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  },

  {
    id: 'auto-hget-bnb',
    logo: 'bnb-pairs/HGET-BNB.svg',
    name: 'HGET-BNB LP',
    token: 'HGET-BNB LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x66b9E1eAc8a81F3752F7f3A5E95dE460688A17Ee',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoHGET-BNB',
    earnedTokenAddress: '0x8f210015B2BbDd39e6d62278992136aCAfd09691',
    earnContractAddress: '0x8f210015B2BbDd39e6d62278992136aCAfd09691',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-hget-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['HGET', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731/BNB',
  },

  {
    id: 'auto-ctk-bnb',
    logo: 'bnb-pairs/CTK-BNB.svg',
    name: 'CTK-BNB LP',
    token: 'CTK-BNB LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x7793870484647a7278907498ec504879d6971EAb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCTK-BNB',
    earnedTokenAddress: '0x00dD62424A79C971D6F6a10bac531F33185E6d5b',
    earnContractAddress: '0x00dD62424A79C971D6F6a10bac531F33185E6d5b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-ctk-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['CTK', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929/BNB',
  },

  {
    id: 'auto-ust-busd',
    logo: 'busd-pairs/UST-BUSD.svg',
    name: 'UST-BUSD LP',
    token: 'UST-BUSD LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0xD1F12370b2ba1C79838337648F820a87eDF5e1e6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUST-BUSD',
    earnedTokenAddress: '0xCa0294b89ee367ADaEDC8cdA684648dC1c5D8FEe',
    earnContractAddress: '0xCa0294b89ee367ADaEDC8cdA684648dC1c5D8FEe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-ust-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['UST', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'auto-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBUSD-BNB',
    earnedTokenAddress: '0xb01e3C7789858beD3b142c8f2499F21Ab3ea3f0f',
    earnContractAddress: '0xb01e3C7789858beD3b142c8f2499F21Ab3ea3f0f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BUSD', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'auto-btc-bnb',
    logo: 'bnb-pairs/BTCB-BNB.svg',
    name: 'BTC-BNB LP',
    token: 'BTC-BNB LP',
    tokenDescription: 'Pancake (Auto)',
    tokenAddress: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBTC-BNB',
    earnedTokenAddress: '0xcf2D6e0030e24F01e4750cAE376E2126e728E0c2',
    earnContractAddress: '0xcf2D6e0030e24F01e4750cAE376E2126e728E0c2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'auto-btc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BTC', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/BNB',
  },

  {
    id: 'venus-btcb',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'Venus',
    tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBTCB',
    earnedTokenAddress: '0x7255Ae13BA4484eD33fB48c4b833000dC12888B4',
    earnContractAddress: '0x7255Ae13BA4484eD33fB48c4b833000dC12888B4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['BTC'],
    callFee: 1,
  },

  {
    id: 'cake-dexe-busd',
    logo: 'busd-pairs/DEXE-BUSD.svg',
    name: 'DEXE-BUSD LP',
    token: 'DEXE-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x69AB367BC1BEA1D2c0fB4dBaEc6b7197951Da56C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDEXE-BUSD',
    earnedTokenAddress: '0xfEDB3855761B9e356671Fb1eC95593e99b153EfD',
    earnContractAddress: '0xfEDB3855761B9e356671Fb1eC95593e99b153EfD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dexe-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['DEXE', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'jul-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.png',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0xd3665568474cC090aB6B243333c2A1A3f13a9C7F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulBIFI-BNB',
    earnedTokenAddress: '0x6a3fF116a10349BB40B22FD7687871d5D560d715',
    earnContractAddress: '0x6a3fF116a10349BB40B22FD7687871d5D560d715',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-bifi-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['BIFI', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl: 'https://julswap.com/#/add/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  },

  {
    id: 'jul-juld-bnb',
    logo: 'bnb-pairs/JULD-BNB.svg',
    name: 'JulD-BNB LP',
    token: 'JulD-BNB SLP',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x0c8f9a56544cc988f1873a038de2f3e4c6fe6f27',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulJulD-BNB',
    earnedTokenAddress: '0xcB8c4416cD10836ff7A35F4b2AfD5BE0257Cc9DB',
    earnContractAddress: '0xcB8c4416cD10836ff7A35F4b2AfD5BE0257Cc9DB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jul-juld-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['JulD', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'venus-ada',
    logo: 'single-assets/ADA.svg',
    name: 'ADA',
    token: 'ADA',
    tokenDescription: 'Venus',
    tokenAddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusADA',
    earnedTokenAddress: '0x53c0CE2EBdA0c2A39327bE0B58a3c28A8Dcde420',
    earnContractAddress: '0x53c0CE2EBdA0c2A39327bE0B58a3c28A8Dcde420',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ADA',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['ADA'],
    callFee: 1,
  },

  {
    id: 'cake-bel-bnb',
    logo: 'bnb-pairs/BEL-BNB.svg',
    name: 'BEL-BNB LP',
    token: 'BEL-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xAB97952a2806D5c92b7046c7aB13a72A87e0097b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBEL-BNB',
    earnedTokenAddress: '0x7C001B962029C0a59b148293838dc780d7E5d24C',
    earnContractAddress: '0x7C001B962029C0a59b148293838dc780d7E5d24C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bel-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BEL', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-tpt-busd',
    logo: 'busd-pairs/TPT-BUSD.svg',
    name: 'TPT-BUSD LP',
    token: 'TPT-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x4db28767D1527bA545CA5bbdA1C96a94ED6ff242',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTPT-BUSD',
    earnedTokenAddress: '0x6b4971b8dAfc3c426EdE7E629394f31B0BdF3c16',
    earnContractAddress: '0x6b4971b8dAfc3c426EdE7E629394f31B0BdF3c16',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-tpt-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['TPT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-watch-bnb',
    logo: 'bnb-pairs/WATCH-BNB.svg',
    name: 'WATCH-BNB LP',
    token: 'WATCH-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xdC6C130299E53ACD2CC2D291fa10552CA2198a6b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeWATCH-BNB',
    earnedTokenAddress: '0x31b4d6D89531BB32307737C441a8cD7d82224B91',
    earnContractAddress: '0x31b4d6D89531BB32307737C441a8cD7d82224B91',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-watch-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['WATCH', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  },

  {
    id: 'cake-xmark-busd',
    logo: 'busd-pairs/XMARK-BUSD.svg',
    name: 'XMARK-BUSD LP',
    token: 'XMARK-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x292Ca56ED5b3330A19037f835Af4A9c0098EA6Fa',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeXMARK-BUSD',
    earnedTokenAddress: '0x9De53755e913913dEcA7360a19C0634F2214FB6d',
    earnContractAddress: '0x9De53755e913913dEcA7360a19C0634F2214FB6d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-xmark-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['XMARK', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'soup-soup-bnb',
    logo: 'degens/SOUP-BNB.svg',
    name: 'SOUP-BNB LP',
    token: 'SOUP-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x284A5D8712C351Ca28417d131003120808dcE48B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSoupSoup-BNB',
    earnedTokenAddress: '0xDB89f01094c6e882d06aBC724aEBc7d15036fe14',
    earnContractAddress: '0xDB89f01094c6e882d06aBC724aEBc7d15036fe14',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'soup-soup-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SoupFinance',
    assets: ['SOUP', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x94F559aE621F1c810F31a6a620Ad7376776fe09E',
  },
  {
    id: 'soup-soups-bnb',
    logo: 'degens/SOUPS-BNB.svg',
    name: 'SOUPS-BNB LP',
    token: 'SOUPS-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x6304Ae062c6bDf3D24Ac86374C7019A025443247',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSoupSoups-BNB',
    earnedTokenAddress: '0xe698edcC279f68c2c37f5c122a26faBd5f6D98A3',
    earnContractAddress: '0xe698edcC279f68c2c37f5c122a26faBd5f6D98A3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'soup-soups-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SoupFinance',
    assets: ['SOUPS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'banana-banana-bnb',
    logo: 'degens/banana-bnb.svg',
    name: 'BANANA-BNB LP',
    token: 'BANANA-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBANANA-BNB',
    earnedTokenAddress: '0x15396D3BD9338A14AE90613Fc2b85c5F7b5621CF',
    earnContractAddress: '0x15396D3BD9338A14AE90613Fc2b85c5F7b5621CF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-banana-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
  },

  {
    id: 'banana-banana-busd',
    logo: 'degens/banana-busd.svg',
    name: 'BANANA-BUSD LP',
    token: 'BANANA-BUSD LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBANANA-BUSD',
    earnedTokenAddress: '0x651b496bCe2C184282a2B9AeF3f8BaDBAC36Dd7f',
    earnContractAddress: '0x651b496bCe2C184282a2B9AeF3f8BaDBAC36Dd7f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-banana-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
  },

  {
    id: 'cake-bmxx-bnb',
    logo: 'bnb-pairs/BMXX-BNB.svg',
    name: 'BMXX-BNB LP',
    token: 'BMXX-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x4D5aA94Ce6BbB1BC4eb73207a5a5d4D052cFcD67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBMXX-BNB',
    earnedTokenAddress: '0xE7DCEFacf1c9c523c1ccCc3Fc1Ae3EB654a4e805',
    earnContractAddress: '0xE7DCEFacf1c9c523c1ccCc3Fc1Ae3EB654a4e805',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bmxx-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BMXX', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-bor-bnb',
    logo: 'bnb-pairs/BOR-BNB.svg',
    name: 'BOR-BNB LP',
    token: 'BOR-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x86E650350C40a5178a5D014ba37FE8556232B898',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBOR-BNB',
    earnedTokenAddress: '0x2EA1175f7189BeAdd6D8A7687B0a311C6785a7a3',
    earnContractAddress: '0x2EA1175f7189BeAdd6D8A7687B0a311C6785a7a3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bor-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BOR', 'BNB'],
  },

  {
    id: 'cake-bopen-bnb',
    logo: 'bnb-pairs/BOPEN-BNB.svg',
    name: 'BOPEN-BNB LP',
    token: 'BOPEN-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x9d8b7e4a9D53654D82F12c83448D8f92732bC761',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBOPEN-BNB',
    earnedTokenAddress: '0x847c5748A280d800690F7D3A62574603b57Cd0b7',
    earnContractAddress: '0x847c5748A280d800690F7D3A62574603b57Cd0b7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bopen-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BOPEN', 'BNB'],
    callFee: 0.5,
  },

  {
    id: '1inch-1inch-bnb',
    logo: 'single-assets/INCH.png',
    name: '1INCH-BNB LP',
    token: '1INCH-BNB LP',
    tokenDescription: '1Inch',
    tokenAddress: '0xdaF66c0B7e8E2FC76B15B07AD25eE58E04a66796',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moo1INCH1INCH-BNB',
    earnedTokenAddress: '0x2139E4CaDA8438a41F4009ff07D09Db29A62De04',
    earnContractAddress: '0x2139E4CaDA8438a41F4009ff07D09Db29A62De04',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: '1inch-1inch-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: '1Inch',
    assets: ['1INCH', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-iotx-busd',
    logo: 'busd-pairs/IOTX-BUSD.svg',
    name: 'IOTX-BUSD LP',
    token: 'IOTX-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x36B8B28D37f93372188F2aa2507b68A5CD8b2664',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeIOTX-BUSD',
    earnedTokenAddress: '0x8a27f380626CAc25DF8D3Ea50Ed1fd7Db882e653',
    earnContractAddress: '0x8a27f380626CAc25DF8D3Ea50Ed1fd7Db882e653',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-iotx-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['IOTX', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'cake-lina-busd',
    logo: 'busd-pairs/LINA-BUSD.svg',
    name: 'LINA-BUSD LP',
    token: 'LINA-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xeB325a8ea1c5abf40c7ceAf645596c1F943d0948',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeLINA-BUSD',
    earnedTokenAddress: '0x310DF4c1DD93337A165AeF8e3d45A770CaaDe3b6',
    earnContractAddress: '0x310DF4c1DD93337A165AeF8e3d45A770CaaDe3b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-lina-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['LINA', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'cake-usdc-busd',
    logo: 'busd-pairs/USDC-BUSD.svg',
    name: 'USDC-BUSD LP',
    token: 'USDC-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x680Dd100E4b394Bda26A59dD5c119A391e747d18',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeUSDC-BUSD',
    earnedTokenAddress: '0x0666bDF7187307890D2ACF092493cCEA51A8CD0b',
    earnContractAddress: '0x0666bDF7187307890D2ACF092493cCEA51A8CD0b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-usdc-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['USDC', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'cake-dai-busd',
    logo: 'busd-pairs/DAI-BUSD.svg',
    name: 'DAI-BUSD LP',
    token: 'DAI-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x3aB77e40340AB084c3e23Be8e5A6f7afed9D41DC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDAI-BUSD',
    earnedTokenAddress: '0xE372825C7b436244Cd5fA79cAa16e4Cc3091D4B0',
    earnContractAddress: '0xE372825C7b436244Cd5fA79cAa16e4Cc3091D4B0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dai-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['DAI', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'sponge-steam-bnb',
    logo: 'bnb-pairs/STEAM-BNB.svg',
    name: 'STEAM-BNB LP',
    token: 'STEAM-BNB LP',
    tokenDescription: 'Sponge',
    tokenAddress: '0x5e797B329e6B82b62442477D976a56864AE4396e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpongeSTEAM-BNB',
    earnedTokenAddress: '0x04D0bE0169694ca083996899a14752c82A953D22',
    earnContractAddress: '0x04D0bE0169694ca083996899a14752c82A953D22',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sponge-steam-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Sponge',
    assets: ['STEAM', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'salt-salt-busd',
    logo: 'degens/salt-busd.png',
    name: 'SALT-BUSD LP',
    token: 'SALT-BUSD LP',
    tokenDescription: 'SaltSwap',
    tokenAddress: '0x6596f770786915556C47E301cC8290aa14288d99',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSaltSalt-BUSD',
    earnedTokenAddress: '0x62ad4253FB38265Da05eb389ac547F1B2c09a689',
    earnContractAddress: '0x62ad4253FB38265Da05eb389ac547F1B2c09a689',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'salt-salt-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SaltSwap',
    assets: ['SALT', 'BUSD'],
    callFee: 0.5,
  },
  {
    id: 'salt-salt-bnb',
    logo: 'degens/salt-bnb.png',
    name: 'SALT-BNB LP',
    token: 'SALT-BNB LP',
    tokenDescription: 'SaltSwap',
    tokenAddress: '0x6284B49544c8900B4612f8450DCE8D484E5c2631',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSaltSalt-BNB',
    earnedTokenAddress: '0x57e07d6958fd38AA2f46699744e322d3fB3db60f',
    earnContractAddress: '0x57e07d6958fd38AA2f46699744e322d3fB3db60f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'salt-salt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SaltSwap',
    assets: ['SALT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-sushi-eth',
    logo: 'uncategorized/SUSHI-ETH.svg',
    name: 'SUSHI-ETH LP',
    token: 'SUSHI-ETH LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x17580340F3dAEDAE871a8C21D15911742ec79e0F', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSUSHI-ETH',
    earnedTokenAddress: '0x07622B8cF9130934643522E7c9D621707EF1d34F',
    earnContractAddress: '0x07622B8cF9130934643522E7c9D621707EF1d34F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-sushi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['SUSHI', 'ETH'],
    callFee: 0.5,
  },

  {
    id: 'crow-crow-busd',
    logo: 'crow/crow.png',
    name: 'CROW-BUSD LP',
    token: 'CROW-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xEC3B97802FB4883FdB7bf70f0924Bf5EE0520cDE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCrowCROW-BUSD',
    earnedTokenAddress: '0x39C803716F67D6d4b190E633a961bF3503b8444E',
    earnContractAddress: '0x39C803716F67D6d4b190E633a961bF3503b8444E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'crow-crow-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CrowFinance',
    assets: ['CROW', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'crow-crow-bnb',
    logo: 'crow/crow.png',
    name: 'CROW-BNB LP',
    token: 'CROW-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x74d2a66650bbd8027Fd1F4A3454a16D75A05cc14',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCrowCROW-BNB',
    earnedTokenAddress: '0xbd701bc24485ebfd3680285fb152509637ab8ad4',
    earnContractAddress: '0xbd701bc24485ebfd3680285fb152509637ab8ad4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'crow-crow-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CrowFinance',
    assets: ['CROW', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'ramen-ramen-bnb',
    logo: 'degens/ramen-bnb.svg',
    name: 'RAMEN-BNB LP',
    token: 'RAMEN-BNB LP',
    tokenDescription: 'RamenSwap',
    tokenAddress: '0x82f504D655ec6dbA8d5eBbd0036a908A719A295f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRamenRamen-BNB',
    earnedTokenAddress: '0x42b41189e9B371B7952b77aE791FE25E78D22681',
    earnContractAddress: '0x42b41189e9B371B7952b77aE791FE25E78D22681',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ramen-ramen-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'RamenSwap',
    assets: ['RAMEN', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.ramenswap.finance/#/add/ETH/0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
  },

  {
    id: 'ramen-ramen-busd',
    logo: 'degens/ramen-busd.svg',
    name: 'RAMEN-BUSD LP',
    token: 'RAMEN-BUSD LP',
    tokenDescription: 'RamenSwap',
    tokenAddress: '0x04F81B56B6947Cd0fD35fbea570fc09d1b946C56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooRamenRamen-BUSD',
    earnedTokenAddress: '0x605d96De6068f08489392a35E9DBE90201574bbC',
    earnContractAddress: '0x605d96De6068f08489392a35E9DBE90201574bbC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ramen-ramen-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'RamenSwap',
    assets: ['RAMEN', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.ramenswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
  },

  {
    id: 'cafe-brew-busd',
    logo: 'degens/brew-busd.svg',
    name: 'BREW-BUSD LP',
    token: 'BREW-BUSD LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0x1fd9af4999de0d61c2a6cbd3d4892b675a082999',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeBREW-BUSD',
    earnedTokenAddress: '0x10120b5550214ab9451b9b00e4f78e82c02d6482',
    earnContractAddress: '0x10120b5550214ab9451b9b00e4f78e82c02d6482',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-brew-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CafeSwap',
    assets: ['BREW', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.cafeswap.finance/#/add/0x790Be81C3cA0e53974bE2688cDb954732C9862e1/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'cafe-brew-bnb',
    logo: 'degens/brew-bnb.svg',
    name: 'BREW-BNB LP',
    token: 'BREW-BNB LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0x723203e821f1ff2d0e396d5dd2ea390f3c9d42cf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeBREW-BNB',
    earnedTokenAddress: '0xEE24B014422a53dA2190a64C0477a300590677f2',
    earnContractAddress: '0xEE24B014422a53dA2190a64C0477a300590677f2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-brew-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CafeSwap',
    assets: ['BREW', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.cafeswap.finance/#/add/0x790Be81C3cA0e53974bE2688cDb954732C9862e1/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },

  {
    id: 'cafe-bifi-bnb',
    logo: 'degens/BIFI-BNB.svg',
    name: 'BIFI-BNB LP',
    token: 'BIFI-BNB LP',
    tokenDescription: 'CafeSwap',
    tokenAddress: '0xd132D2C24F29EE8ABb64a66559d1b7aa627Bd7fD', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCafeBIFI-BNB',
    earnedTokenAddress: '0x8afc0f9BdC5DcA9f0408Df03A03520bFa98A15aF',
    earnContractAddress: '0x8afc0f9BdC5DcA9f0408Df03A03520bFa98A15aF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cafe-bifi-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'CafeSwap',
    assets: ['BIFI', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.cafeswap.finance/#/add/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },

  {
    id: 'cake-bry-bnb',
    logo: 'bnb-pairs/BRY-BNB.svg',
    name: 'BRY-BNB LP',
    token: 'BRY-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x5E3CD27F36932Bc0314aC4e2510585798C34a2fC', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBRYBNB',
    earnedTokenAddress: '0x578C066b5c7289f8Ba7c4DB43c110F19D7c51E03',
    earnContractAddress: '0x578C066b5c7289f8Ba7c4DB43c110F19D7c51E03',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bry-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BRY', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-zee-bnb',
    logo: 'bnb-pairs/ZEE-BNB.svg',
    name: 'ZEE-BNB LP',
    token: 'ZEE-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xB5Ab3996808c7e489DCDc0f1Af2AB212ae0059aF', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeZEEBNB',
    earnedTokenAddress: '0x29C4C22eC7dF7e936Cb3e6C4A7E5eAE751C7d86B',
    earnContractAddress: '0x29C4C22eC7dF7e936Cb3e6C4A7E5eAE751C7d86B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-zee-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['ZEE', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-xtz-bnb',
    logo: 'bnb-pairs/XTZ-BNB.svg',
    name: 'XTZ-BNB LP',
    token: 'XTZ-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x5acaC332F0F49c8bAdC7aFd0134aD19D3DB972e6', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeXTZBNB',
    earnedTokenAddress: '0x12e09a13f5e2d95b3B8db8741dFeBa453784d1DC',
    earnContractAddress: '0x12e09a13f5e2d95b3B8db8741dFeBa453784d1DC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-xtz-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['XTZ', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-bch-bnb',
    logo: 'bnb-pairs/BCH-BNB.svg',
    name: 'BCH-BNB LP',
    token: 'BCH-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x54EdD846dB17f43b6e43296134ECD96284671E81', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBCHBNB',
    earnedTokenAddress: '0x646C536865603cFEB51dd35C36FD90296FB8DF26',
    earnContractAddress: '0x646C536865603cFEB51dd35C36FD90296FB8DF26',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bch-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BCH', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-dodo-bnb',
    logo: 'bnb-pairs/DODO-BNB.svg',
    name: 'DODO-BNB LP',
    token: 'DODO-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x9e642d174B14fAEa31D842Dc83037c42b53236E6', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDODO-BNB',
    earnedTokenAddress: '0x00AeC34489A7ADE91A0507B6b9dBb0a50938B7c0',
    earnContractAddress: '0x00AeC34489A7ADE91A0507B6b9dBb0a50938B7c0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dodo-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['DODO', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-swingby-bnb',
    logo: 'bnb-pairs/SWINGBY-BNB.svg',
    name: 'SWINGBY-BNB LP',
    token: 'SWINGBY-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x4576C456AF93a37a096235e5d83f812AC9aeD027', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSWINGBY-BNB',
    earnedTokenAddress: '0xc1C1eB984218B9570beA53C0Dad14283a6E9E81C',
    earnContractAddress: '0xc1C1eB984218B9570beA53C0Dad14283a6E9E81C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-swingby-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['SWINGBY', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'kebab-usdt-busd',
    logo: 'kebab/USDT-BUSD.png',
    name: 'USDT-BUSD LP',
    token: 'USDT-BUSD LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabUSDT-BUSD',
    earnedTokenAddress: '0x9dc7d9c4488223c0Ad50F0AE742D80AE1abEc859',
    earnContractAddress: '0x9dc7d9c4488223c0Ad50F0AE742D80AE1abEc859',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-usdt-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kebab',
    assets: ['USDT', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'kebab-eth-btcb',
    logo: 'kebab/ETH-BTCB.png',
    name: 'ETH-BTCB LP',
    token: 'ETH-BTCB LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0x7380E10F5C5f9DFF4857de3cf9c39Bb16F4C6dcf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabETH-BTCB',
    earnedTokenAddress: '0x70CBc8B3a74A857056a6acE7e994229A24D4ab94',
    earnContractAddress: '0x70CBc8B3a74A857056a6acE7e994229A24D4ab94',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-eth-btcb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kebab',
    assets: ['ETH', 'BTC'],
    callFee: 0.5,
  },

  {
    id: 'monster-msc-busd',
    logo: 'single-assets/MSC.png',
    name: 'MSC-BUSD LP',
    token: 'MSC-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xbCbF0C95C94798fFEF0e71A087a7E98F29D5C0Ee',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMonsterMSC-BUSD',
    earnedTokenAddress: '0x1d9a453FCe2AC7b92FBe7b33BdC40F6e7aE14F47',
    earnContractAddress: '0x1d9a453FCe2AC7b92FBe7b33BdC40F6e7aE14F47',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'monster-msc-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'MonsterSlayer',
    assets: ['MSC', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8C784C49097Dcc637b93232e15810D53871992BF',
  },

  {
    id: 'monster-msc-bnb',
    logo: 'single-assets/MSC.png',
    name: 'MSC-BNB LP',
    token: 'MSC-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x981DB69F2F2F96e0f08D6519BEFDa0B927C22190',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMonsterMSC-BNB',
    earnedTokenAddress: '0x0F88Ce20f8af6D4c51b635BFBd8DFa03f2a36ACa',
    earnContractAddress: '0x0F88Ce20f8af6D4c51b635BFBd8DFa03f2a36ACa',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'monster-msc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'MonsterSlayer',
    assets: ['MSC', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-bts-busd',
    logo: 'single-assets/BTS.png',
    name: 'BTS-BUSD LP',
    token: 'BTS-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xE86493D416EEB7800F08787de759d179B61dB921',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoltDollarBTS-BUSD',
    earnedTokenAddress: '0x6b40106ebEDaA719B14cfbA606b89954EaeeaE9D',
    earnContractAddress: '0x6b40106ebEDaA719B14cfbA606b89954EaeeaE9D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bts-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BoltDollar',
    assets: ['BTS', 'BUSD'],
    callFee: 0.5,
  },
  {
    id: 'cake-btd-busd',
    logo: 'single-assets/BTD.png',
    name: 'BTD-BUSD LP',
    token: 'BTD-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xebaaA639a55ecBEbAC4D7D43A5A6D7C5c1216A09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBoltDollarBTD-BUSD',
    earnedTokenAddress: '0x0D3837E00C47353e68680C39e6DA6B5b08fE6897',
    earnContractAddress: '0x0D3837E00C47353e68680C39e6DA6B5b08fE6897',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-btd-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'BoltDollar',
    assets: ['BTD', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0xD1102332a213E21faF78B69C03572031F3552c33/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'auto-btc',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBtc',
    earnedTokenAddress: '0xFf750EE63F1E428De1D6C8D9d92676b5FC8376A8',
    earnContractAddress: '0xFf750EE63F1E428De1D6C8D9d92676b5FC8376A8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BTC'],
    callFee: 0.5,
  },

  {
    id: 'auto-eth',
    logo: 'single-assets/ETH.png',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoEth',
    earnedTokenAddress: '0x0a406FCBEA86aC57ACcc812E8F1B509acE5E054c',
    earnContractAddress: '0x0a406FCBEA86aC57ACcc812E8F1B509acE5E054c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Autofarm',
    assets: ['ETH'],
    callFee: 0.5,
  },

  {
    id: 'auto-busd',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBusdFixed',
    earnedTokenAddress: '0x559C88946e1958812dCfD8edd4f89B0C7d811F74',
    earnContractAddress: '0x559C88946e1958812dCfD8edd4f89B0C7d811F74',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BUSD'],
    callFee: 0.5,
  },

  {
    id: 'auto-usdt',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0x55d398326f99059ff775485246999027b3197955',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUsdt',
    earnedTokenAddress: '0x58327471A57EE89Aa4b91753fF9E09f7291236C7',
    earnContractAddress: '0x58327471A57EE89Aa4b91753fF9E09f7291236C7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Autofarm',
    assets: ['USDT'],
    callFee: 0.5,
  },

  {
    id: 'auto-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoUsdc',
    earnedTokenAddress: '0x7E45e8E3404a2C142d7E8eAE94EaEB8641607857',
    earnContractAddress: '0x7E45e8E3404a2C142d7E8eAE94EaEB8641607857',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Autofarm',
    assets: ['USDC'],
    callFee: 0.5,
  },

  {
    id: 'kebab-kebab-btc',
    logo: 'kebab/KEBAB-BTCB.png',
    name: 'KEBAB-BTCB LP',
    token: 'KEBAB-BTCB LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0x45D8D5d9bD425Efc26e3259848BCFEfA16F927F0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabKEBAB-BTCB',
    earnedTokenAddress: '0x02a77B01BE5BB6a73b70E2b5615694F71a2c4F41',
    earnContractAddress: '0x02a77B01BE5BB6a73b70E2b5615694F71a2c4F41',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-kebab-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB', 'BTC'],
    callFee: 0.5,
  },

  // FIXME: apy missing
  // {
  //   id: 'nyanswop-nya-bnb',
  //   logo: 'single-assets/NYA.png',
  //   name: 'NYA-BNB NYAN-LP',
  //   token: 'NYA-BNB NYAN-LP',
  //   tokenDescription: 'Nyanswop',
  //   tokenAddress: '0x8283C220e764B54aaB8a9Bae7d547984428B4Cd5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooNyanswopNYA-BNB',
  //   earnedTokenAddress: '0x652858C737E48DF61D79dC3C76BEbE1A2F7561c9',
  //   earnContractAddress: '0x652858C737E48DF61D79dC3C76BEbE1A2F7561c9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'nyanswop-nya-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Nyanswop',
  //   assets: ['NYA', 'BNB'],
  //   callFee: 0.5,
  // },

  // {
  //   id: 'nyanswop-usdt-bnb',
  //   logo: 'single-assets/USDT.svg',
  //   name: 'USDT-BNB NYAN-LP',
  //   token: 'USDT-BNB NYAN-LP',
  //   tokenDescription: 'Nyanswop',
  //   tokenAddress: '0x90984c51ec476dba73acce45ccd94df8c5716c55',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooNyanswopUSDT-BNB',
  //   earnedTokenAddress: '0x593025Cb6eE00cf55d0037BD850da332FFF8379D',
  //   earnContractAddress: '0x593025Cb6eE00cf55d0037BD850da332FFF8379D',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'nyanswop-usdt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Nyanswop',
  //   assets: ['USDT', 'BNB'],
  //   callFee: 0.5,
  // },

  {
    id: 'cake-swgb-bnb',
    logo: 'bnb-pairs/SWGB-BNB.svg',
    name: 'SWGB-BNB LP',
    token: 'SWGB-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xC1800c29CF91954357cd0bf3f0accAADa3D0109c', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSWGB-BNB',
    earnedTokenAddress: '0xed324b068e31c6fB7C7921e79E54d95B1Ca9d759',
    earnContractAddress: '0xed324b068e31c6fB7C7921e79E54d95B1Ca9d759',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-swgb-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['SWGB', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-lit-bnb',
    logo: 'bnb-pairs/LIT-BNB.svg',
    name: 'LIT-BNB LP',
    token: 'LIT-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x60bB03D1010b99CEAdD0dd209b64bC8bd83da161', // vault
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeLIT-BNB',
    earnedTokenAddress: '0x747f66e2Ab1b887a2207e15ADb7f9dAB79D9EAcf',
    earnContractAddress: '0x747f66e2Ab1b887a2207e15ADb7f9dAB79D9EAcf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-lit-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['LIT', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
  },

  {
    id: 'cake-hard-bnb',
    logo: 'bnb-pairs/HARD-BNB.svg',
    name: 'HARD-BNB LP',
    token: 'HARD-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x9F40e8a2Fcaa267A0c374B6c661E0b372264cC3D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeHARD-BNB',
    earnedTokenAddress: '0xc41BB9dEF52Dd7ceD10c27fF2cCEC3299603299B',
    earnContractAddress: '0xc41BB9dEF52Dd7ceD10c27fF2cCEC3299603299B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-hard-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['HARD', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'kebab-bifi-busd',
    logo: 'busd-pairs/BIFI-BUSD.png',
    name: 'BIFI-BUSD LP',
    token: 'BIFI-BUSD LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0xd3F004E303114423f122c78AFDeD4AcfE97675B1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabBIFI-BUSD',
    earnedTokenAddress: '0x9c1630eA66310fA03D756FBd92B68a1f08c487b6',
    earnContractAddress: '0x9c1630eA66310fA03D756FBd92B68a1f08c487b6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-bifi-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kebab',
    assets: ['BIFI', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://swap.kebabfinance.com/#/add/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'cake-ditto-bnb',
    logo: 'bnb-pairs/DITTO-BNB.svg',
    name: 'DITTO-BNB LP',
    token: 'DITTO-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x470BC451810B312BBb1256f96B0895D95eA659B1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDITTO-BNB',
    earnedTokenAddress: '0x8FF55869e05492c127cEEc6A400D7e3803fC44E1',
    earnContractAddress: '0x8FF55869e05492c127cEEc6A400D7e3803fC44E1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ditto-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['DITTO', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'auto-cake-old',
    logo: 'single-assets/SMART.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoCake',
    earnedTokenAddress: '0x895CF1E805cBd86355caC101aE9B252c806Dc59b',
    earnContractAddress: '0x895CF1E805cBd86355caC101aE9B252c806Dc59b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['CAKE'],
    callFee: 0.5,
  },

  {
    id: 'auto-wbnb-old',
    logo: 'single-assets/WBNB.svg',
    name: 'WBNB',
    token: 'WBNB',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoWbnb',
    earnedTokenAddress: '0x8136C5EE816fD80b80C9679e785F37EBdf2199eE',
    earnContractAddress: '0x8136C5EE816fD80b80C9679e785F37EBdf2199eE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Autofarm',
    assets: ['BNB'],
    callFee: 0.5,
  },

  {
    id: 'auto-busd-old',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'Venus (Auto)',
    tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooAutoBusd',
    earnedTokenAddress: '0xBd198f5132631e597518529268000A0199f07492',
    earnContractAddress: '0xBd198f5132631e597518529268000A0199f07492',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Autofarm',
    assets: ['BUSD'],
    callFee: 0.5,
  },

  {
    id: 'sponge-sponge-bnb',
    logo: 'bnb-pairs/SPG-BNB.svg',
    name: 'SPG-BNB LP',
    token: 'SPG-BNB LP',
    tokenDescription: 'Sponge',
    tokenAddress: '0xBB74572F3C00B4d18fAbeADb7d5984D7b3AE05d1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpongeSPG-BNB',
    earnedTokenAddress: '0x1B3564F698988804b5D05a2C10B1d71bE051662F',
    earnContractAddress: '0x1B3564F698988804b5D05a2C10B1d71bE051662F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sponge-sponge-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Sponge',
    assets: ['SPG', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'sponge-soak-bnb',
    logo: 'bnb-pairs/SOAK-BNB.svg',
    name: 'SOAK-BNB LP',
    token: 'SOAK-BNB LP',
    tokenDescription: 'Sponge',
    tokenAddress: '0xc8FCBA816eF9B0c5bc03d9178CfCc7EF785B4f2f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpongeSOAK-BNB',
    earnedTokenAddress: '0xE035Dce0E345Cbd46F3c0dCBf602f96c5F741813',
    earnContractAddress: '0xE035Dce0E345Cbd46F3c0dCBf602f96c5F741813',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sponge-soak-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Sponge',
    assets: ['SOAK', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-sfp-bnb',
    logo: 'bnb-pairs/SFP-BNB.svg',
    name: 'SFP-BNB LP',
    token: 'SFP-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xcBe2cF3bd012e9C1ADE2Ee4d41DB3DaC763e78F3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSFP-BNB',
    earnedTokenAddress: '0xCa38d57755f29c9ad17AbB1A6ae059DdAD913A0e',
    earnContractAddress: '0xCa38d57755f29c9ad17AbB1A6ae059DdAD913A0e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-sfp-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['SFP', 'BNB'],
    callFee: 0.5,
  },

  // Disable until stable ~$1
  // {
  //   id: 'nyacash-nyac-usdt',
  //   logo: 'single-assets/NYAC.png',
  //   name: 'NYAC-USDT NYAN-LP',
  //   token: 'NYAC-USDT NYAN-LP',
  //   tokenDescription: 'Nyanswop',
  //   tokenAddress: '0x6cDdb562f0259ed1489F1e6Fa05815Dec769f8Ad',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooNyacashNYAC-BNB',
  //   earnedTokenAddress: '0x5f8B766062645F0Af98840ceF5d1bCd1d2684A63',
  //   earnContractAddress: '0x5f8B766062645F0Af98840ceF5d1bCd1d2684A63',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'nyanswop-nyac-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Nyanswop',
  //   assets: ['NYAC', 'USDT'],
  //   callFee: 0.5,
  // },

  {
    id: 'nyacash-nyas-usdt',
    logo: 'single-assets/NYAS.png',
    name: 'NYAS-USDT NYAN-LP',
    token: 'NYAS-USDT NYAN-LP',
    tokenDescription: 'Nyanswop',
    tokenAddress: '0xd3179b44548f3309622B4F966503C1143e8840Ca',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooNyacashNYAS-BNB',
    earnedTokenAddress: '0x5ee82A50D1C7a1B599a5d4DA2f54AdB0d7a497a6',
    earnContractAddress: '0x5ee82A50D1C7a1B599a5d4DA2f54AdB0d7a497a6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'nyanswop-nyas-usdt',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Nyanswop',
    assets: ['NYAS', 'USDT'],
    callFee: 0.5,
  },

  {
    id: 'monster-mss-busd',
    logo: 'single-assets/MSS.png',
    name: 'MSS-BUSD LP',
    token: 'MSS-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x73601076A92E0D1bD81737B38B150D6842aa1aA1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMonsterMSS-BUSD',
    earnedTokenAddress: '0x3B6C7bC3BE5C60c875cF415A97e6539cC28ECa32',
    earnContractAddress: '0x3B6C7bC3BE5C60c875cF415A97e6539cC28ECa32',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'monster-mss-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'MonsterSlayer',
    assets: ['MSS', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'jul-juld',
    logo: 'single-assets/JULD.png',
    name: 'JULD',
    token: 'JULD',
    tokenDescription: 'JulSwap',
    tokenAddress: '0x5a41f637c3f7553dba6ddc2d3ca92641096577ea',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJulJULD',
    earnedTokenAddress: '0x90d8AB2731f937f9ec98d0bbe6D4CBD4D95473db',
    earnContractAddress: '0x90d8AB2731f937f9ec98d0bbe6D4CBD4D95473db',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'coingecko',
    oracleId: 'julswap',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JulSwap',
    assets: ['JulD'],
    callFee: 0.5,
  },

  {
    id: 'cake-bdo-bnb',
    logo: 'single-assets/BDO.png',
    name: 'BDO-BNB LP',
    token: 'BDO-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBDO-BNB',
    earnedTokenAddress: '0x48Cb3540CB0BB4C6dDF638209e0631Ec85c1a477',
    earnContractAddress: '0x48Cb3540CB0BB4C6dDF638209e0631Ec85c1a477',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BDO', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'kebab-kebab',
    logo: 'single-assets/KEBAB.png',
    name: 'KEBAB',
    token: 'KEBAB',
    tokenDescription: 'Kebab',
    tokenAddress: '0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebab',
    earnedTokenAddress: '0xE2231D4ca4921Cb35680bac714C9d40C8d217494',
    earnContractAddress: '0xE2231D4ca4921Cb35680bac714C9d40C8d217494',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'KEBAB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB'],
    callFee: 0.5,
  },

  {
    id: 'kebab-kebab-busd',
    logo: 'busd-pairs/KEBAB-BUSD.png',
    name: 'KEBAB-BUSD LP',
    token: 'KEBAB-BUSD LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0xD51bee2E0A3886289F6D229b6f30c0C2b34fC0Ec',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabKEBAB-BUSD',
    earnedTokenAddress: '0xF0A7626eCcBee00aF144BB1f77CD187AF85BBf41',
    earnContractAddress: '0xF0A7626eCcBee00aF144BB1f77CD187AF85BBf41',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-kebab-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'kebab-kebab-bnb',
    logo: 'bnb-pairs/KEBAB-BNB.png',
    name: 'KEBAB-BNB LP',
    token: 'KEBAB-BNB LP',
    tokenDescription: 'Kebab',
    tokenAddress: '0x25fd42d82d5c238ee7aa277261aa6ca5bdfe5cd4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKebabKEBAB-BNB',
    earnedTokenAddress: '0x2Eb92E2882030B8e153370d208462D4dDf7041d2',
    earnContractAddress: '0x2Eb92E2882030B8e153370d208462D4dDf7041d2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'kebab-kebab-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Kebab',
    assets: ['KEBAB', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-egld-bnb',
    logo: 'bnb-pairs/EGLD-BNB.svg',
    name: 'EGLD-BNB LP',
    token: 'EGLD-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x3Ef4952C7a9AfbE374EA02d1Bf5eD5a0015b7716',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeEGLD-BNB',
    earnedTokenAddress: '0x3d0bd3C73eF66C8b487A49f50410173C44261285',
    earnContractAddress: '0x3d0bd3C73eF66C8b487A49f50410173C44261285',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-egld-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['EGLD', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-mamzn-ust',
    logo: 'ust-pairs/MAMZN-UST.svg',
    name: 'MAMZN-UST LP',
    token: 'MAMZN-UST LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xc92Dc34665c8a21f98E1E38474580b61b4f3e1b9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMAMZN-UST',
    earnedTokenAddress: '0x69009287479805E7372284835627564bad02107D',
    earnContractAddress: '0x69009287479805E7372284835627564bad02107D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mamzn-ust',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['MAMZN', 'UST'],
    callFee: 0.5,
  },

  {
    id: 'cake-mgoogl-ust',
    logo: 'ust-pairs/MGOOGL-UST.svg',
    name: 'MGOOGL-UST LP',
    token: 'MGOOGL-UST LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x852A68181f789AE6d1Da3dF101740a59A071004f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMGOOGL-UST',
    earnedTokenAddress: '0xfBa61aC24B46c5B7298De02FBa8F577C1c5005e9',
    earnContractAddress: '0xfBa61aC24B46c5B7298De02FBa8F577C1c5005e9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mgoogl-ust',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['MGOOGL', 'UST'],
    callFee: 0.5,
  },

  {
    id: 'cake-mnflx-ust',
    logo: 'ust-pairs/MNFLX-UST.svg',
    name: 'MNFLX-UST LP',
    token: 'MNFLX-UST LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xF609ade3846981825776068a8eD7746470029D1f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMNFLX-UST',
    earnedTokenAddress: '0x7185017145ea364163cCd708E0c78AC0297D6A94',
    earnContractAddress: '0x7185017145ea364163cCd708E0c78AC0297D6A94',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mnflx-ust',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['MNFLX', 'UST'],
    callFee: 0.5,
  },

  {
    id: 'cake-mtsla-ust',
    logo: 'ust-pairs/MTSLA-UST.svg',
    name: 'MTSLA-UST LP',
    token: 'MTSLA-UST LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xD5664D2d15cdffD597515f1c0D945c6c1D3Bf85B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeMTSLA-UST',
    earnedTokenAddress: '0x42BeB2855aa620C5c9dD073FA888c43c8a65c34f',
    earnContractAddress: '0x42BeB2855aa620C5c9dD073FA888c43c8a65c34f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-mtsla-ust',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['MTSLA', 'UST'],
    callFee: 0.5,
  },

  {
    id: 'cake-wsote-bnb',
    logo: 'bnb-pairs/WSOTE-BNB.svg',
    name: 'WSOTE-BNB LP',
    token: 'WSOTE-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xFfb9E2d5ce4378F1a89b29bf53F80804CC078102',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeWSOTE-BNB',
    earnedTokenAddress: '0x5B6c3432B724Ce198B93769970f1dF36Ac5552F8',
    earnContractAddress: '0x5B6c3432B724Ce198B93769970f1dF36Ac5552F8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-wsote-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['WSOTE', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-front-bnb',
    logo: 'bnb-pairs/FRONT-BNB.svg',
    name: 'FRONT-BNB LP',
    token: 'FRONT-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x36b7D2e5C7877392Fb17f9219efaD56F3D794700',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeFRONT-BNB',
    earnedTokenAddress: '0x1Ae7E76e2Eb74070774bbd9EAC75585452f24C23',
    earnContractAddress: '0x1Ae7E76e2Eb74070774bbd9EAC75585452f24C23',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-front-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['FRONT', 'BNB'],
    callFee: 0.5,
  },

  // {
  //   id: 'cake-helmet-bnb',
  //   logo: 'bnb-pairs/HELMET-BNB.svg',
  //   name: 'HELMET-BNB LP',
  //   token: 'HELMET-BNB LP',
  //   tokenDescription: 'Pancake',
  //   tokenAddress: '0x6411310C07d8c48730172146Fd6F31FA84034a8b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeHELMET-BNB',
  //   earnedTokenAddress: '0x3F8134F8508a57a4f3b28342d5A6F3DCFB119D1d',
  //   earnContractAddress: '0x3F8134F8508a57a4f3b28342d5A6F3DCFB119D1d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-helmet-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Pancake',
  //   assets: ['HELMET', 'BNB'],
  //   callFee: 0.5,
  // },

  {
    id: 'cake-btcst-bnb',
    logo: 'bnb-pairs/BTCST-BNB.svg',
    name: 'BTCST-BNB LP',
    token: 'BTCST-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x91589786D36fEe5B27A5539CfE638a5fc9834665',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBTCST-BNB',
    earnedTokenAddress: '0xfC083Cb104b48924AB44C69537b1450B2e78eBD6',
    earnContractAddress: '0xfC083Cb104b48924AB44C69537b1450B2e78eBD6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-btcst-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BTCST', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'bhc-bhc',
    logo: 'single-assets/BHC.png',
    name: 'BHC',
    token: 'BHC',
    tokenDescription: 'BillionHappiness',
    tokenAddress: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBHC',
    earnedTokenAddress: '0x56E899E56F6B8635B4A01b724db23674a64A7231',
    earnContractAddress: '0x56E899E56F6B8635B4A01b724db23674a64A7231',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BHC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    assets: ['BHC'],
    platform: 'BillionHappiness',
    callFee: 0.5,
  },

  {
    id: 'thugs-drugs-btri',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS BTRI',
    token: 'DRUGS​​​​​​',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsBTRI',
    earnedTokenAddress: '0xFAE4a35305a1dcED8303029ac05e51386f667ED8',
    earnContractAddress: '0xFAE4a35305a1dcED8303029ac05e51386f667ED8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['DRUGS'],
    callFee: 0.5,
  },

  {
    id: 'cake-ltc-bnb',
    logo: 'bnb-pairs/LTC-BNB.svg',
    name: 'LTC-BNB LP',
    token: 'LTC-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xbc765fd113c5bdb2ebc25f711191b56bb8690aec',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeLTC-BNB',
    earnedTokenAddress: '0x4D6294D36aD4201De1D93Af18A61453B8865d008',
    earnContractAddress: '0x4D6294D36aD4201De1D93Af18A61453B8865d008',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ltc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['LTC', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-ada-bnb',
    logo: 'bnb-pairs/ADA-BNB.svg',
    name: 'ADA-BNB LP',
    token: 'ADA-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xba51d1ab95756ca4eab8737ecd450cd8f05384cf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeADA-BNB',
    earnedTokenAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    earnContractAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ada-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['ADA', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-dot-bnb',
    logo: 'bnb-pairs/DOT-BNB.svg',
    name: 'DOT-BNB LP',
    token: 'DOT-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xbcd62661a6b1ded703585d3af7d7649ef4dcdb5c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeDOT-BNB',
    earnedTokenAddress: '0xb8b7CAF72D75Ac8b71bB208b2D98a9dac9d5c90B',
    earnContractAddress: '0xb8b7CAF72D75Ac8b71bB208b2D98a9dac9d5c90B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-dot-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['DOT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'bakery-bake-busd',
    logo: 'busd-pairs/BAKE-BUSD.png',
    name: 'BAKE-BUSD BLP',
    token: 'BAKE-BUSD BLP',
    tokenDescription: 'Bakery',
    tokenAddress: '0x6E218EA042BeF40a8baF706b00d0f0A7b4fCE50a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAKE-BUSD',
    earnedTokenAddress: '0x23293B8A7c65aF396Bda8Ff75E02c62f6db8f202',
    earnContractAddress: '0x23293B8A7c65aF396Bda8Ff75E02c62f6db8f202',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-bake-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAKE', 'BUSD'],
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  },

  {
    id: 'jetfuel-vapor-bnb',
    logo: 'bnb-pairs/VAPOR-BNB.png',
    name: 'VAPOR-BNB LP',
    token: 'VAPOR-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x01ffd4B8dE49442ab3EF755cC6bb57deEB6B871a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfVAPOR-BNB',
    earnedTokenAddress: '0x00EcbB8f9853dbC621b39ab205a148A26eAC3B11',
    earnContractAddress: '0x00EcbB8f9853dbC621b39ab205a148A26eAC3B11',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-vapor-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'JetFuel',
    assets: ['VAPOR', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-bdo-bnb-sbdo',
    logo: 'single-assets/SBDO.png',
    name: 'BDO-BNB LP (SBDO)',
    token: 'BDO-BNB LP (SBDO)',
    tokenDescription: 'Pancake',
    tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarBDO-BNB-SBDO',
    earnedTokenAddress: '0x9355af5ABD030E8BC2Dc63a38F1C956F1e150308',
    earnContractAddress: '0x9355af5ABD030E8BC2Dc63a38F1C956F1e150308',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-bnb-sbdo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bDollar',
    assets: ['BDO', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-bdo-bnb-old',
    logo: 'single-assets/BDO.png',
    name: 'BDO-BNB LP',
    token: 'BDO-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarBDO-BNB',
    earnedTokenAddress: '0x2B19461954cCBa9e1b60bD0bC6453FeaC8aa1a17',
    earnContractAddress: '0x2B19461954cCBa9e1b60bD0bC6453FeaC8aa1a17',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'bDollar',
    assets: ['BDO', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-sbdo-busd',
    logo: 'single-assets/SBDO.png',
    name: 'SBDO-BUSD LP',
    token: 'SBDO-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xA0718093baa3E7AAE054eED71F303A4ebc1C076f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarSBDO-BUSD',
    earnedTokenAddress: '0x9327029cf20B78e445397dAf0c4e9c19a931D62e',
    earnContractAddress: '0x9327029cf20B78e445397dAf0c4e9c19a931D62e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-sbdo-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bDollar',
    assets: ['SBDO', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'cake-bdo-busd',
    logo: 'single-assets/SBDO.png',
    name: 'BDO-BUSD LP',
    token: 'BDO-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xc5b0d73A7c0E4eaF66baBf7eE16A2096447f7aD6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBdollarBDO-BUSD',
    earnedTokenAddress: '0x0fA31301EaA9633b3e4F7dF22F7543115759cf5a',
    earnContractAddress: '0x0fA31301EaA9633b3e4F7dF22F7543115759cf5a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bdo-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'bDollar',
    assets: ['BDO', 'BUSD'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'venus-beth',
    logo: 'single-assets/BETH.png',
    name: 'BETH',
    token: 'BETH',
    tokenDescription: 'Venus',
    tokenAddress: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBETH',
    earnedTokenAddress: '0xB38D32336538559Ce6680952B0Be4917ed015c39',
    earnContractAddress: '0xB38D32336538559Ce6680952B0Be4917ed015c39',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['BETH'],
    callFee: 1,
  },

  {
    id: 'venus-dai',
    logo: 'single-assets/DAI.svg',
    name: 'DAI',
    token: 'DAI',
    tokenDescription: 'Venus',
    tokenAddress: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusDAI',
    earnedTokenAddress: '0x813aDbc7b9a9822FA37510F52aD14bDBc45aA306',
    earnContractAddress: '0x813aDbc7b9a9822FA37510F52aD14bDBc45aA306',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DAI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['DAI'],
    callFee: 1,
  },

  {
    id: 'venus-fil',
    logo: 'single-assets/FIL.svg',
    name: 'FIL',
    token: 'FIL',
    tokenDescription: 'Venus',
    tokenAddress: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusFIL',
    earnedTokenAddress: '0x8B80E58328aEF3796D70D29F65d516d29ACD944d',
    earnContractAddress: '0x8B80E58328aEF3796D70D29F65d516d29ACD944d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FIL',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['FIL'],
    callFee: 1,
  },

  {
    id: 'cake-ten-bnb',
    logo: 'bnb-pairs/TEN-BNB.svg',
    name: 'TEN-BNB LP',
    token: 'TEN-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x01ecc44Ddd2D104F44D2AA1A2bD9DFbC91aE8275',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTEN-BNB',
    earnedTokenAddress: '0x3040387C46e21B453e4a8f648528368b9066628B',
    earnContractAddress: '0x3040387C46e21B453e4a8f648528368b9066628B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-ten-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['TEN', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-balbt-bnb',
    logo: 'bnb-pairs/BALBT-BNB.svg',
    name: 'bALBT-BNB LP',
    token: 'bALBT-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xbe14f3a89A4F7f279Af9d99554cf12E8C29dB921',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakebALBT-BNB',
    earnedTokenAddress: '0xc2c792807A32fB9F9f985749Ef90673D8e1Bad44',
    earnContractAddress: '0xc2c792807A32fB9F9f985749Ef90673D8e1Bad44',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-balbt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['bALBT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-bscx-bnb',
    logo: 'bnb-pairs/BSCX-BNB.svg',
    name: 'BSCX-BNB LP',
    token: 'BSCX-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x20781bc3701c5309ac75291f5d09bdc23d7b7fa8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBSCX-BNB',
    earnedTokenAddress: '0xb5f0ff997bec850b11792ed07b2b5abdea869b84',
    earnContractAddress: '0xb5f0ff997bec850b11792ed07b2b5abdea869b84',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-bscx-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BSCX', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'bakery-btc-bnb',
    logo: 'bnb-pairs/BTC-BNB.png',
    name: 'BTC-BNB BLP',
    token: 'BTC-BNB BLP',
    tokenDescription: 'Bakery',
    tokenAddress: '0x58521373474810915b02FE968D1BCBe35Fc61E09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBTC-BNB',
    earnedTokenAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    earnContractAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-btc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Bakery',
    assets: ['BTC', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/ETH/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  },

  {
    id: 'street-bhc-bnb',
    logo: 'bnb-pairs/BHC-BNB.png',
    name: 'BHC-BNB TLP',
    token: 'BHC-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBHC-BNB',
    earnedTokenAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
    earnContractAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-bhc-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['BHC', 'BNB'],
    callFee: 1,
  },

  {
    id: 'street-jgn-bnb',
    logo: 'bnb-pairs/JGN-BNB.png',
    name: 'JGN-BNB TLP',
    token: 'JGN-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xc7C4726D0A45E4e7392901A90dCB52Ec4d2Bd1a3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetJGN-BNB',
    earnedTokenAddress: '0x34199dc9ad1bD3D4A6EC70c120B8780e3A2Ca6C7',
    earnContractAddress: '0x34199dc9ad1bD3D4A6EC70c120B8780e3A2Ca6C7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-jgn-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['JGN', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-unfi-bnb',
    logo: 'bnb-pairs/UNFI-BNB.svg',
    name: 'UNFI-BNB LP',
    token: 'UNFI-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xbEA35584b9a88107102ABEf0BDeE2c4FaE5D8c31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeUNFI-BNB',
    earnedTokenAddress: '0x03090F5E40934E0F1AAe73b2F1cFD240DBD7Af5E',
    earnContractAddress: '0x03090F5E40934E0F1AAe73b2F1cFD240DBD7Af5E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-unfi-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['UNFI', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-reef-bnb',
    logo: 'bnb-pairs/REEF-BNB.svg',
    name: 'REEF-BNB LP',
    token: 'REEF-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x58B58cab6C5cF158f63A2390b817710826d116D0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeREEF-BNB',
    earnedTokenAddress: '0x6E0F2e199FC10B3F354C6825fCbC24a5de96B4bF',
    earnContractAddress: '0x6E0F2e199FC10B3F354C6825fCbC24a5de96B4bF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-reef-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['REEF', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-stax-cake',
    logo: 'cake-pairs/STAX-CAKE.svg',
    name: 'STAX-CAKE LP',
    token: 'STAX-CAKE LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x7cd05f8b960Ba071FdF69C750c0E5a57C8366500',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeSTAX-CAKE',
    earnedTokenAddress: '0xB194bcA26660abC93042fd6b475F2dD0b5175ED7',
    earnContractAddress: '0xB194bcA26660abC93042fd6b475F2dD0b5175ED7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-stax-cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Pancake',
    assets: ['STAX', 'CAKE'],
    callFee: 1,
  },

  {
    id: 'cake-nar-cake',
    logo: 'cake-pairs/NAR-CAKE.svg',
    name: 'NAR-CAKE LP',
    token: 'NAR-CAKE LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x745C4fD226E169d6da959283275A8E0EcDd7F312',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeNAR-CAKE',
    earnedTokenAddress: '0xFaa95d27A91eb2305bD1B4b12a9cE564874eE349',
    earnContractAddress: '0xFaa95d27A91eb2305bD1B4b12a9cE564874eE349',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-nar-cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Pancake',
    assets: ['NAR', 'CAKE'],
    callFee: 1,
  },
  {
    id: 'cake-nya-cake',
    logo: 'cake-pairs/NYA-CAKE.svg',
    name: 'NYA-CAKE LP',
    token: 'NYA-CAKE LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x2730bf486d658838464A4ef077880998D944252d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeNYA-CAKE',
    earnedTokenAddress: '0x5f640a217026FeFD0C4F1E581b44794c3d5F4638',
    earnContractAddress: '0x5f640a217026FeFD0C4F1E581b44794c3d5F4638',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-nya-cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Pancake',
    assets: ['NYA', 'CAKE'],
    callFee: 1,
  },

  {
    id: 'thugs-drugs-drugs',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS v2',
    token: 'DRUGS v2',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsGunsV2',
    earnedTokenAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
    earnContractAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['DRUGS'],
    callFee: 0.5,
  },

  {
    id: 'thugs-drugs-bhc',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS BHC',
    token: 'DRUGS​',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsBHC',
    earnedTokenAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
    earnContractAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['DRUGS'],
    callFee: 0.5,
  },

  {
    id: 'thugs-drugs-jgn',
    logo: 'single-assets/DRUGS.png',
    name: 'DRUGS JGN',
    token: 'DRUGS​​',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooDrugsJGN',
    earnedTokenAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
    earnContractAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DRUGS',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['DRUGS'],
    callFee: 0.5,
  },

  {
    id: 'jetfuel-fuel-bnb-ss',
    logo: 'bnb-pairs/FUEL-BNB.png',
    name: 'FUEL-BNB TLP',
    token: 'FUEL-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xd26092c6ce6FB359302A9453dc0c7f312794Ef7E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfFUEL-BNB-SS',
    earnedTokenAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
    earnContractAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-fuel-bnb-ss',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['FUEL', 'BNB'],
    callFee: 1,
  },

  {
    id: 'jetfuel-fuel-bnb-pcs',
    logo: 'bnb-pairs/FUEL-BNB.png',
    name: 'FUEL-BNB LP',
    token: 'FUEL-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x3763A3263CEaca5e7Cc1Bc22A43920bAd9f743Cd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfFUEL-BNB-PCS',
    earnedTokenAddress: '0x9d3Aa54172D49659Ee9948d1b7D602989d39Ffa6',
    earnContractAddress: '0x9d3Aa54172D49659Ee9948d1b7D602989d39Ffa6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-fuel-bnb-pcs',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['FUEL', 'BNB'],
    callFee: 1,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
  },

  {
    id: 'jetfuel-bhc-bnb',
    logo: 'bnb-pairs/BHC-BNB.png',
    name: 'BHC-BNB LP',
    token: 'BHC-BNB LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfBHC-BNB',
    earnedTokenAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
    earnContractAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-bhc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['BHC', 'BNB'],
    callFee: 1,
  },

  {
    id: 'jetfuel-eth-bnb',
    logo: 'bnb-pairs/ETH-BNB.svg',
    name: 'ETH-BNB LP',
    token: 'ETH-BNB LP',
    tokenDescription: 'Pancake (Fuel)',
    tokenAddress: '0x70D8929d04b60Af4fb9B58713eBcf18765aDE422',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfETH-BNB',
    earnedTokenAddress: '0x25Fb99cf1DCe844cD823bD1e8FB0c1DCc85dAD0E',
    earnContractAddress: '0x25Fb99cf1DCe844cD823bD1e8FB0c1DCc85dAD0E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-eth-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['ETH', 'BNB'],
    callFee: 1,
  },

  {
    id: 'jetfuel-cake-bnb',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP',
    tokenDescription: 'Pancake (Fuel)',
    tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfCAKE-BNB',
    earnedTokenAddress: '0xC0766D0F482c7174274bE653dcB0B80c0436dFCc',
    earnContractAddress: '0xC0766D0F482c7174274bE653dcB0B80c0436dFCc',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-cake-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['CAKE', 'BNB'],
    callFee: 1,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  },

  {
    id: 'jetfuel-uni-bnb',
    logo: 'bnb-pairs/UNI-BNB.svg',
    name: 'UNI-BNB LP',
    token: 'UNI-BNB LP',
    tokenDescription: 'Pancake (Fuel)',
    tokenAddress: '0x4269e7F43A63CEA1aD7707Be565a94a9189967E9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfUNI-BNB',
    earnedTokenAddress: '0xD96c8527d20acfa7D5D2054Bfb375fd0cB428322',
    earnContractAddress: '0xD96c8527d20acfa7D5D2054Bfb375fd0cB428322',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-uni-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['UNI', 'BNB'],
    callFee: 1,
  },

  {
    id: 'jetfuel-link-bnb',
    logo: 'bnb-pairs/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB LP',
    tokenDescription: 'Pancake (Fuel)',
    tokenAddress: '0xaeBE45E3a03B734c68e5557AE04BFC76917B4686',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfLINK-BNB',
    earnedTokenAddress: '0x73fA0bbAb2e3288123674200553fBfCb94037Fcb',
    earnContractAddress: '0x73fA0bbAb2e3288123674200553fBfCb94037Fcb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-link-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['LINK', 'BNB'],
    callFee: 1,
  },

  {
    id: 'jetfuel-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP',
    tokenDescription: 'Pancake (Fuel)',
    tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfBUSD-BNB',
    earnedTokenAddress: '0x8af2D7289A093CF1019D9e20554F6c7aD5791A94',
    earnContractAddress: '0x8af2D7289A093CF1019D9e20554F6c7aD5791A94',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['BUSD', 'BNB'],
    callFee: 1,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'jetfuel-usdt-bnb',
    logo: 'bnb-pairs/USDT-BNB.svg',
    name: 'USDT-BNB LP',
    token: 'USDT-BNB LP',
    tokenDescription: 'Pancake (Fuel)',
    tokenAddress: '0x20bCC3b8a0091dDac2d0BC30F68E6CBb97de59Cd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJfUSDT-BNB',
    earnedTokenAddress: '0x629C4ca0F3492476FAe31dE6FD456D7da1dfCEb7',
    earnContractAddress: '0x629C4ca0F3492476FAe31dE6FD456D7da1dfCEb7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetfuel-usdt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetFuel',
    assets: ['USDT', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-smart',
    logo: 'single-assets/SMART.png',
    name: 'CAKE SMART',
    token: 'CAKE',
    tokenDescription: 'Pancake',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/pancake-swap#cake-smart',
    earnedToken: 'mooCakeSmart',
    earnedTokenAddress: '0xB0BDBB9E507dBF55f4aC1ef6ADa3E216202e06FD',
    earnContractAddress: '0xB0BDBB9E507dBF55f4aC1ef6ADa3E216202e06FD',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['CAKE'],
    callFee: 0.5,
  },

  {
    id: 'venus-bch',
    logo: 'single-assets/BCH.svg',
    name: 'BCH',
    token: 'BCH',
    tokenDescription: 'Venus',
    tokenAddress: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBCH',
    earnedTokenAddress: '0xf19878DE084C3c9753bB0DAD8aC5b9070fb7813c',
    earnContractAddress: '0xf19878DE084C3c9753bB0DAD8aC5b9070fb7813c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BCH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['BCH'],
    callFee: 1,
  },

  {
    id: 'venus-dot',
    logo: 'single-assets/DOT.png',
    name: 'DOT',
    token: 'DOT',
    tokenDescription: 'Venus',
    tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusDOT',
    earnedTokenAddress: '0x2211191ff72505ED54Aa6aD3F6Fcc72c87CAb338',
    earnContractAddress: '0x2211191ff72505ED54Aa6aD3F6Fcc72c87CAb338',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DOT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['DOT'],
    callFee: 1,
  },

  {
    id: 'venus-link',
    logo: 'single-assets/LINK.png',
    name: 'LINK',
    token: 'LINK',
    tokenDescription: 'Venus',
    tokenAddress: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusLINK',
    earnedTokenAddress: '0x8da7167860EDfc2bFfd790f217AB5e398803Bbc8',
    earnContractAddress: '0x8da7167860EDfc2bFfd790f217AB5e398803Bbc8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LINK',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['LINK'],
    callFee: 1,
  },

  {
    id: 'street-twt-bnb',
    logo: 'bnb-pairs/TWT-BNB.svg',
    name: 'TWT-BNB TLP',
    token: 'TWT-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xf7f21A56B19546A77EABABf23d8dca726CaF7577',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetTWT-BNB',
    earnedTokenAddress: '0x23f81b26e7dfB44832f627Ee533399285a11e711',
    earnContractAddress: '0x23f81b26e7dfB44832f627Ee533399285a11e711',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-twt-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['TWT', 'BNB'],
    callFee: 1,
  },

  {
    id: 'street-sxp-bnb',
    logo: 'bnb-pairs/SXP-BNB.svg',
    name: 'SXP-BNB TLP',
    token: 'SXP-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xf08865069864A5a62EB4DD4b9dcB66834822a198',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetSXP-BNB',
    earnedTokenAddress: '0x0F5ef847595236Bb70833825FC9Bf0ef982CDD4C',
    earnContractAddress: '0x0F5ef847595236Bb70833825FC9Bf0ef982CDD4C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-sxp-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['SXP', 'BNB'],
    callFee: 1,
  },

  {
    id: 'street-xvs-bnb',
    logo: 'bnb-pairs/XVS-BNB.svg',
    name: 'XVS-BNB TLP',
    token: 'XVS-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x52a7FBe0995607B74e77A367036E76BCE11A3B44',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetXVS-BNB',
    earnedTokenAddress: '0x7ca3761D9886D5Ac233E58d5B2E60174B3A94b82',
    earnContractAddress: '0x7ca3761D9886D5Ac233E58d5B2E60174B3A94b82',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-xvs-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['XVS', 'BNB'],
    callFee: 1,
  },

  {
    id: 'street-alpha-bnb',
    logo: 'bnb-pairs/ALPHA-BNB.svg',
    name: 'ALPHA-BNB TLP',
    token: 'ALPHA-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x8d27dccB0fb3d6621fA4F4155d719f4AF159286e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetALPHA-BNB',
    earnedTokenAddress: '0x1061c2962fccD794C4131b3c13058302618329E9',
    earnContractAddress: '0x1061c2962fccD794C4131b3c13058302618329E9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-alpha-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['ALPHA', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-yfii-bnb',
    logo: 'bnb-pairs/YFII-BNB.svg',
    name: 'YFII-BNB LP',
    token: 'YFII-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x574a978c2D0d36D707a05E459466C7A1054F1210',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeYFII-BNB',
    earnedTokenAddress: '0xcB00A85dbb29Ab9e2581660031BF56EfC819a559',
    earnContractAddress: '0xcB00A85dbb29Ab9e2581660031BF56EfC819a559',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-yfii-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['YFII', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-yfi-bnb',
    logo: 'bnb-pairs/YFI-BNB.svg',
    name: 'YFI-BNB LP',
    token: 'YFI-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x68Ff2ca47D27db5Ac0b5c46587645835dD51D3C1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeYFI-BNB',
    earnedTokenAddress: '0xc119408BDa86C12F1c6BD276e68ab9f558292119',
    earnContractAddress: '0xc119408BDa86C12F1c6BD276e68ab9f558292119',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-yfi-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['YFI', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-fil-bnb',
    logo: 'bnb-pairs/FIL-BNB.svg',
    name: 'FIL-BNB LP',
    token: 'FIL-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x35FE9787F0eBF2a200BAc413D3030CF62D312774',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeFIL-BNB',
    earnedTokenAddress: '0xA74b54B24106B008881e627EC57D9629C2fdA5e2',
    earnContractAddress: '0xA74b54B24106B008881e627EC57D9629C2fdA5e2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-fil-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['FIL', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-band-bnb',
    logo: 'bnb-pairs/BAND-BNB.svg',
    name: 'BAND-BNB LP',
    token: 'BAND-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xc639187ef82271D8f517de6FEAE4FaF5b517533c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBAND-BNB',
    earnedTokenAddress: '0x9368165eCB0BB16d214fc63fDFcd07fbBa21AbBB',
    earnContractAddress: '0x9368165eCB0BB16d214fc63fDFcd07fbBa21AbBB',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-band-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BAND', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-atom-bnb',
    logo: 'bnb-pairs/ATOM-BNB.svg',
    name: 'ATOM-BNB LP',
    token: 'ATOM-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x2333c77FC0B2875c11409cdCD3C75D42D402E834',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeATOM-BNB',
    earnedTokenAddress: '0x4884cD2C9F11Dc9e8f90d177576c46fdbd1b7641',
    earnContractAddress: '0x4884cD2C9F11Dc9e8f90d177576c46fdbd1b7641',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-atom-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['ATOM', 'BNB'],
    callFee: 1,
  },

  {
    id: 'cake-vai-busd',
    logo: 'busd-pairs/VAI-BUSD.svg',
    name: 'VAI-BUSD LP',
    token: 'VAI-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xfF17ff314925Dff772b71AbdFF2782bC913B3575',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeVAI-BUSD',
    earnedTokenAddress: '0xa40488536B1BAeECA9D2277Fde28394D3c04aD73',
    earnContractAddress: '0xa40488536B1BAeECA9D2277Fde28394D3c04aD73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-vai-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['VAI', 'BUSD'],
    callFee: 1,
  },

  {
    id: 'cake-broobee-cake',
    logo: 'cake-pairs/BROOBEE-CAKE.svg',
    name: 'BROOBEE-CAKE LP',
    token: 'BROOBEE-CAKE LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x970858016C963b780E06f7DCfdEf8e809919BcE8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeBROOBEE-CAKE',
    earnedTokenAddress: '0x4CcF7dC91389396801ec1f4A5E6cC675549f39E6',
    earnContractAddress: '0x4CcF7dC91389396801ec1f4A5E6cC675549f39E6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-broobee-cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Pancake',
    assets: ['BROOBEE', 'CAKE'],
    callFee: 1,
  },

  {
    id: 'venus-bnb',
    logo: 'single-assets/BNB.png',
    name: 'BNB',
    token: 'BNB',
    tokenDescription: 'Venus',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBNB',
    earnedTokenAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    earnContractAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['BNB'],
    callFee: 1,
  },

  {
    id: 'venus-wbnb',
    logo: 'single-assets/WBNB.svg',
    name: 'WBNB',
    token: 'WBNB',
    tokenDescription: 'Venus',
    tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBNB',
    earnedTokenAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    earnContractAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WBNB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['BNB'],
    callFee: 1,
  },

  {
    id: 'venus-sxp',
    logo: 'single-assets/SXP.svg',
    name: 'SXP',
    token: 'SXP',
    tokenDescription: 'Venus',
    tokenAddress: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusSXP',
    earnedTokenAddress: '0x9aaD247bAc2AFc3027256aD0Cc09A27551069bd6',
    earnContractAddress: '0x9aaD247bAc2AFc3027256aD0Cc09A27551069bd6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SXP',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['SXP'],
    callFee: 1,
  },

  {
    id: 'venus-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Venus',
    tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusUSDC',
    earnedTokenAddress: '0x07D478b679C6c7F4222388856f53F8dca7E7b378',
    earnContractAddress: '0x07D478b679C6c7F4222388856f53F8dca7E7b378',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['USDC'],
    callFee: 1,
  },

  {
    id: 'venus-usdt',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'Venus',
    tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusUSDT',
    earnedTokenAddress: '0x1997D7cF0A63A374A853ca9d5b1dAcCFeCa5cEb8',
    earnContractAddress: '0x1997D7cF0A63A374A853ca9d5b1dAcCFeCa5cEb8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['USDT'],
    callFee: 1,
  },

  {
    id: 'venus-busd',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'Venus',
    tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusBUSD',
    earnedTokenAddress: '0x2d3d152b3b94c0E0B217AC5bc1115E3D8b8018F9',
    earnContractAddress: '0x2d3d152b3b94c0E0B217AC5bc1115E3D8b8018F9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['BUSD'],
    callFee: 1,
  },

  {
    id: 'venus-ltc',
    logo: 'single-assets/LTC.svg',
    name: 'LTC',
    token: 'LTC',
    tokenDescription: 'Venus',
    tokenAddress: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusLTC',
    earnedTokenAddress: '0xd61420f20e945d0B617fEdA5971f692A3DCE711c',
    earnContractAddress: '0xd61420f20e945d0B617fEdA5971f692A3DCE711c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LTC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['LTC'],
    callFee: 1,
  },

  {
    id: 'venus-xrp',
    logo: 'single-assets/XRP.svg',
    name: 'XRP',
    token: 'XRP',
    tokenDescription: 'Venus',
    tokenAddress: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooVenusXRP',
    earnedTokenAddress: '0x3eb560209B438d4e23833aFf6751fcf0B810F60b',
    earnContractAddress: '0x3eb560209B438d4e23833aFf6751fcf0B810F60b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'coingecko',
    oracleId: 'ripple',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Venus',
    assets: ['XRP'],
    callFee: 1,
  },

  {
    id: 'cake-alpha-bnb',
    logo: 'bnb-pairs/ALPHA-BNB.svg',
    name: 'ALPHA-BNB LP',
    token: 'ALPHA-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x4e0f3385d932F7179DeE045369286FFa6B03d887',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeALPHA-BNB',
    earnedTokenAddress: '0xB1B91AA761F552FEF29A50643D0F93690082960F',
    earnContractAddress: '0xB1B91AA761F552FEF29A50643D0F93690082960F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-alpha-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['ALPHA', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-inj-bnb',
    logo: 'bnb-pairs/INJ-BNB.svg',
    name: 'INJ-BNB LP',
    token: 'INJ-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x7a34bd64d18e44CfdE3ef4B81b87BAf3EB3315B6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeINJ-BNB',
    earnedTokenAddress: '0xD16Aef70403f1Fbf710C6A73EBb7c4FaA983d59a',
    earnContractAddress: '0xD16Aef70403f1Fbf710C6A73EBb7c4FaA983d59a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-inj-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['INJ', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xa2B726B1145A4773F68593CF171187d8EBe4d495',
  },

  {
    id: 'cake-twt-bnb',
    logo: 'bnb-pairs/TWT-BNB.svg',
    name: 'TWT-BNB LP',
    token: 'TWT-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x610e7a287c27dfFcaC0F0a94f547Cc1B770cF483',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeTWT-BNB',
    earnedTokenAddress: '0x3b2c93f9455786e74C68648EaaB09B20ce557537',
    earnContractAddress: '0x3b2c93f9455786e74C68648EaaB09B20ce557537',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-twt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['TWT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-xvs-bnb',
    logo: 'bnb-pairs/XVS-BNB.svg',
    name: 'XVS-BNB LP',
    token: 'XVS-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x41182c32F854dd97bA0e0B1816022e0aCB2fc0bb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooPancakeXVS-BNB',
    earnedTokenAddress: '0xbB63c8858b64a856d79A80575Df60867622E4782',
    earnContractAddress: '0xbB63c8858b64a856d79A80575Df60867622E4782',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-xvs-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['XVS', 'BNB'],
    callFee: 0.5,
  },

  // Narwhal

  {
    id: 'narwhal-gold-bnb',
    logo: 'bnb-pairs/GOLD-BNB.png',
    name: 'GOLD-BNB NLP',
    token: 'GOLD-BNB NLP',
    tokenDescription: 'Narwhal',
    tokenAddress: '0x903903A2aa2A222360D8f3c4A7205b933Da60e95',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooNarwhalGOLD-BNB',
    earnedTokenAddress: '0x179701FD814F17838605c1990EDA96C1EbeC984e',
    earnContractAddress: '0x179701FD814F17838605c1990EDA96C1EbeC984e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'narwhal-gold-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Narwhal',
    assets: ['GOLD', 'BNB'],
    callFee: 1,
  },

  {
    id: 'narwhal-thugs-nar',
    logo: 'uncategorized/THUGS-NAR.png',
    name: 'THUGS-NAR NLP',
    token: 'THUGS-NAR NLP',
    tokenDescription: 'Narwhal',
    tokenAddress: '0xB648ecF23Bb8fe854c6267d6fb046f2d483251EA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooNarwhalTHUGS-NAR',
    earnedTokenAddress: '0xCa6e313C535B6C355bEeAae296B58a194C3D6044',
    earnContractAddress: '0xCa6e313C535B6C355bEeAae296B58a194C3D6044',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'narwhal-thugs-nar',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Narwhal',
    assets: ['THUGS', 'NAR'],
    callFee: 1,
  },

  // Bakery

  {
    id: 'bakery-bake',
    logo: 'single-assets/BAKE.png',
    name: 'BAKE',
    token: 'BAKE',
    tokenDescription: 'Bakery',
    tokenAddress: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAKE',
    earnedTokenAddress: '0xaC112E7744C129ae54E65F5D2cb4eA472E08eA0B',
    earnContractAddress: '0xaC112E7744C129ae54E65F5D2cb4eA472E08eA0B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BAKE',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAKE'],
    callFee: 0.5,
  },

  {
    id: 'bakery-bake-bnb',
    logo: 'bnb-pairs/BAKE-BNB.png',
    name: 'BAKE-BNB BLP',
    token: 'BAKE-BNB BLP',
    tokenDescription: 'Bakery',
    tokenAddress: '0xc2Eed0F5a0dc28cfa895084bC0a9B8B8279aE492',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBakeryBAKE-BNB',
    earnedTokenAddress: '0x3e1B60EB79b17d41F8E268936d853A43BEA24259',
    earnContractAddress: '0x3e1B60EB79b17d41F8E268936d853A43BEA24259',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'bakery-bake-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Bakery',
    assets: ['BAKE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://www.bakeryswap.org/#/add/ETH/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  },

  {
    id: 'street-bch-bnb',
    logo: 'bnb-pairs/BCH-BNB.svg',
    name: 'BCH-BNB TLP',
    token: 'BCH-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xcE7b1Bc3ff9115B64Bf4D6B1549f8339D02762F4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBCH-BNB',
    earnedTokenAddress: '0x1bcc82ce9b387E16fC383af563fd400074E3f060',
    earnContractAddress: '0x1bcc82ce9b387E16fC383af563fd400074E3f060',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-bch-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['BCH', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-nar-bnb',
    logo: 'bnb-pairs/NAR-BNB.png',
    name: 'NAR-BNB TLP',
    token: 'NAR-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x1389003bcb228586648721A4F6187004dAE01c51',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetNAR-BNB',
    earnedTokenAddress: '0x06fb2e27155c2a551aF34E80aE67f569673dDD73',
    earnContractAddress: '0x06fb2e27155c2a551aF34E80aE67f569673dDD73',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-nar-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['NAR', 'BNB'],
    callFee: 0.5,
  },
  {
    id: 'street-bifi-bnb',
    logo: 'bnb-pairs/BIFI-BNB.png',
    name: 'BIFI-BNB TLP',
    token: 'BIFI-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x223b53b64aa5f87d2de194a15701fc0bc7474a31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBIFI-BNB',
    earnedTokenAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
    earnContractAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-bifi-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['BIFI', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-drugs-bnb',
    logo: 'bnb-pairs/DRUGS-BNB.png',
    name: 'DRUGS-BNB LP v2',
    token: 'DRUGS-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x421DF185Ff87bc5f19bd5a90102A51452b70c4A4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetDRUGS-BNBV2',
    earnedTokenAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
    earnContractAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-drugs-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['DRUGS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-thugs-bnb',
    logo: 'bnb-pairs/THUGS-BNB.png',
    name: 'THUGS-BNB LP v2',
    token: 'THUGS-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x9354caB0F1083135439B23aA6c364329e578F39a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetTHUGS-BNBV2',
    earnedTokenAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
    earnContractAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-thugs-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['THUGS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-cred-bnb',
    logo: 'bnb-pairs/CRED-BNB.png',
    name: 'CRED-BNB LP',
    token: 'CRED-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x9CBE4F200b474a1C33bfFe7c6e531e1D47ECab46',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetCRED-BNB',
    earnedTokenAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
    earnContractAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-cred-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['CRED', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-guns-bnb',
    logo: 'bnb-pairs/GUNS-BNB.png',
    name: 'GUNS-BNB LP v2',
    token: 'GUNS-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xa5e5eeB6b51fa0fDc59915C81d75C83470BFA4f0',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-guns-bnb-lp',
    earnedToken: 'mooStreetGUNS-BNBV2',
    earnedTokenAddress: '0x68aE2aB6448884714DDf11C8d55ea7578C65D502',
    earnContractAddress: '0x68aE2aB6448884714DDf11C8d55ea7578C65D502',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-guns-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['GUNS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP v2',
    token: 'BUSD-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xf2e4E3F9B58b3eDaC88Ad11D689a23f3119a782D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBUSD-BNBV2',
    earnedTokenAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
    earnContractAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-busd-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['BUSD', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-panacakelp-thugs-bnb',
    logo: 'bnb-pairs/THUGS-BNB.png',
    name: 'THUGS-BNB Pancake LP v2',
    token: 'THUGS-BNB Pancake LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x40257481f09db2211a3274b34554bbb00b0a7fb1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetPancakeLP-THUGS-BNBV2',
    earnedTokenAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
    earnContractAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-panacakelp-thugs-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['THUGS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-dai-bnb',
    logo: 'bnb-pairs/DAI-BNB.svg',
    name: 'DAI-BNB LP',
    token: 'DAI-BNB LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xed8Ecb790Be568461d85Cf82C386E51124E46a52',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetDAI-BNB',
    earnedTokenAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
    earnContractAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-dai-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['DAI', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-usdt-bnb',
    logo: 'bnb-pairs/USDT-BNB.svg',
    name: 'USDT-BNB TLP',
    token: 'USDT-BNB TLP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x0d29724d1834Fc65869812BAe5d63dce8aCB7921',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetUSDT-BNB',
    earnedTokenAddress: '0x390f28E3919D2c4d9624Ae9AeBe6da25322ED56D',
    earnContractAddress: '0x390f28E3919D2c4d9624Ae9AeBe6da25322ED56D',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-usdt-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['USDT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-bliq-bnb',
    logo: 'bnb-pairs/BLIQ-BNB.png',
    name: 'BLIQ-BNB LP',
    token: 'BLIQ-BNB LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0xe835ed82C7d9d018f8F5173EECfBb56f29f38570',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetBLIQ-BNB',
    earnedTokenAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
    earnContractAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-bliq-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['BLIQ', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-eos-bnb',
    logo: 'bnb-pairs/EOS-BNB.svg',
    name: 'EOS-BNB LP',
    token: 'EOS-BNB LP',
    tokenDescription: 'Traphouse',
    tokenAddress: '0x44e89861096371246ea8e3e86d97781ea24fbb29',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooStreetEOS-BNB',
    earnedTokenAddress: '0xd4572009c7580DEfa0251FFD811b04386Dd41570',
    earnContractAddress: '0xd4572009c7580DEfa0251FFD811b04386Dd41570',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-eos-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['EOS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-drugs-bnb-v1',
    logo: 'bnb-pairs/DRUGS-BNB.png',
    name: 'DRUGS-BNB LP v1',
    token: 'DRUGS-BNB LP v1',
    tokenDescription: 'Please migrate to v2',
    tokenAddress: '0x5BcE5d58682f15541EFc72d8f56C7d6221d541f1',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-drugs-bnb-lp',
    earnedToken: 'mooStreetDRUGS-BNB',
    earnedTokenAddress: '0xa4aB709f5eB75f3213734fC227D9e27352d1DB6c',
    earnContractAddress: '0xa4aB709f5eB75f3213734fC227D9e27352d1DB6c',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-drugs-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['DRUGS', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'street-guns-bnb-v1',
    logo: 'bnb-pairs/GUNS-BNB.png',
    name: 'GUNS-BNB LP v1',
    token: 'GUNS-BNB LP v1',
    tokenDescription: 'Please migrate to v2',
    tokenAddress: '0xa5e5eeB6b51fa0fDc59915C81d75C83470BFA4f0',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-guns-bnb-lp',
    earnedToken: 'mooStreetGUNS-BNB',
    earnedTokenAddress: '0xA2bcB1D7955AEa2F5B93C308E199337a77E7bAd4',
    earnContractAddress: '0xA2bcB1D7955AEa2F5B93C308E199337a77E7bAd4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'street-guns-bnb',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Thugs',
    assets: ['GUNS', 'BNB'],
    callFee: 0.5,
  },

  //
  // < ---- > PANCAKE < ---- >
  //
  {
    id: 'cake-cake',
    logo: 'single-assets/CAKE.png',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'Please use Smart Cake',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake',
    earnedToken: 'mooCake',
    earnedTokenAddress: '0xc713ca7cb8edfE238ea652D772d41dde47A9a62d',
    earnContractAddress: '0xc713ca7cb8edfE238ea652D772d41dde47A9a62d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Pancake',
    assets: ['CAKE'],
    callFee: 0.5,
  },

  {
    id: 'cake-twt',
    logo: 'single-assets/cakeTWT.png',
    name: 'CAKE TWT v2',
    token: 'CAKE',
    tokenDescription: 'Pancake',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-twt-with-cake',
    earnedToken: 'mooCakeTWTV2',
    earnedTokenAddress: '0x260879777DB293541DbC9a27c3C2fAD9c9bb48D3',
    earnContractAddress: '0x260879777DB293541DbC9a27c3C2fAD9c9bb48D3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Pancake',
    assets: ['CAKE'],
    callFee: 0.5,
  },

  {
    id: 'cake-hard',
    logo: 'single-assets/cakeHARD.png',
    name: 'CAKE HARD',
    token: 'CAKE',
    tokenDescription: 'Pancake',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-hard-with-cake',
    earnedToken: 'mooCakeHARD',
    earnedTokenAddress: '0x4B4e5F2CeEdfF00D2DD13874714C1Ef6Ea1Cd8a7',
    earnContractAddress: '0x4B4e5F2CeEdfF00D2DD13874714C1Ef6Ea1Cd8a7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'Pancake',
    assets: ['CAKE'],
    callFee: 0.5,
  },

  {
    id: 'cake-cake-bnb',
    logo: 'bnb-pairs/CAKE-BNB.svg',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp',
    earnedToken: 'mooPancakeCAKE-BNB',
    earnedTokenAddress: '0x502AB55Cf22f38c4fd8663dEE041A96B72264c53',
    earnContractAddress: '0x502AB55Cf22f38c4fd8663dEE041A96B72264c53',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-cake-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['CAKE', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  },

  {
    id: 'cake-link-bnb',
    logo: 'bnb-pairs/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xaebe45e3a03b734c68e5557ae04bfc76917b4686',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-link-bnb-lp',
    earnedToken: 'mooPancakeLINK-BNB',
    earnedTokenAddress: '0xaB9C4a0fD179315b005a5f6D3793cb30Af718152',
    earnContractAddress: '0xaB9C4a0fD179315b005a5f6D3793cb30Af718152',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-link-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['LINK', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-usdt-bnb',
    logo: 'bnb-pairs/USDT-BNB.svg',
    name: 'USDT-BNB LP',
    token: 'USDT-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x20bcc3b8a0091ddac2d0bc30f68e6cbb97de59cd',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-usdt-bnb-lp',
    earnedToken: 'mooPancakeUSDT-BNB',
    earnedTokenAddress: '0x72F758cb79cA71CE9010dc9c0fA0155431435B72',
    earnContractAddress: '0x72F758cb79cA71CE9010dc9c0fA0155431435B72',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-usdt-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['USDT', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-busd-bnb-lp',
    earnedToken: 'mooPancakeBNB-BUSD',
    earnedTokenAddress: '0x0c36Bee5cF5E5b1BE5371574cEBF9A5f01DE5Fc4',
    earnContractAddress: '0x0c36Bee5cF5E5b1BE5371574cEBF9A5f01DE5Fc4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BUSD', 'BNB'],
    callFee: 0.5,
    addLiquidityUrl:
      'https://exchange.pancakeswap.finance/#/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },

  {
    id: 'cake-usdt-busd',
    logo: 'busd-pairs/USDT-BUSD.svg',
    name: 'USDT-BUSD LP',
    token: 'USDT-BUSD LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-usdt-busd-lp',
    earnedToken: 'mooPancakeUSDT-BUSD',
    earnedTokenAddress: '0x348039C69B5fD7b333CE0a17E8361E501D2e3fa8',
    earnContractAddress: '0x348039C69B5fD7b333CE0a17E8361E501D2e3fa8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-usdt-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['USDT', 'BUSD'],
    callFee: 0.5,
  },

  {
    id: 'cake-eth-bnb',
    logo: 'bnb-pairs/ETH-BNB.svg',
    name: 'ETH-BNB LP',
    token: 'ETH-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-eth-bnb-lp',
    earnedToken: 'mooPancakeETH-BNB',
    earnedTokenAddress: '0x1CfEAef83Bb688Ac5926df1feEB8F05244054C07',
    earnContractAddress: '0x1CfEAef83Bb688Ac5926df1feEB8F05244054C07',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-eth-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['ETH', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'cake-btcb-bnb',
    logo: 'bnb-pairs/BTCB-BNB.svg',
    name: 'BTCB-BNB LP',
    token: 'BTCB-BNB LP',
    tokenDescription: 'Pancake',
    tokenAddress: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-btcb-bnb-lp',
    earnedToken: 'mooPancakeBNB-BTCB',
    earnedTokenAddress: '0xd9542B833C6B0D9E4f882d2c4F01d5b5bd005Ac7',
    earnContractAddress: '0xd9542B833C6B0D9E4f882d2c4F01d5b5bd005Ac7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cake-btcb-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Pancake',
    assets: ['BTC', 'BNB'],
    callFee: 0.5,
  },

  {
    id: 'fortube-fil',
    logo: 'single-assets/FIL.svg',
    name: 'FIL',
    token: 'FIL',
    tokenDescription: 'ForTube',
    tokenAddress: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://filecoin.io/',
    earnedToken: 'mooForFIL',
    earnedTokenAddress: '0x5Bea18aBa4825bbE6450c280fBE8c22B1489cDd4',
    earnContractAddress: '0x5Bea18aBa4825bbE6450c280fBE8c22B1489cDd4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'coingecko',
    oracleId: 'filecoin',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ForTube',
    assets: ['FIL'],
    callFee: 1,
  },

  {
    id: 'fortube-busd',
    logo: 'single-assets/BUSD.svg',
    name: 'BUSD',
    token: 'BUSD',
    tokenDescription: 'ForTube',
    tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://www.paxos.com/busd/',
    earnedToken: 'mooBUSD',
    earnedTokenAddress: '0xD6fca61Edb1cD0259320A6E0899E8aD1044BBCda',
    earnContractAddress: '0xD6fca61Edb1cD0259320A6E0899E8aD1044BBCda',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BUSD',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ForTube',
    assets: ['BUSD'],
    callFee: 1,
  },

  {
    id: 'fortube-link',
    logo: 'single-assets/LINK.png',
    name: 'ChainLink',
    token: 'LINK',
    tokenDescription: 'ForTube',
    tokenAddress: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://chain.link/',
    earnedToken: 'mooLINK',
    earnedTokenAddress: '0x7bF55acDe006C398Bb605Ae564A8F832F80000ee',
    earnContractAddress: '0x7bF55acDe006C398Bb605Ae564A8F832F80000ee',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'LINK',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ForTube',
    assets: ['LINK'],
    callFee: 1,
  },

  {
    id: 'fortube-dot',
    logo: 'single-assets/DOT.png',
    name: 'DOT',
    token: 'DOT',
    tokenDescription: 'ForTube',
    tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://polkadot.network/',
    earnedToken: 'mooDOT',
    earnedTokenAddress: '0x63a2CEF28904956552714ddb226ceE96c3F0C9EF',
    earnContractAddress: '0x63a2CEF28904956552714ddb226ceE96c3F0C9EF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DOT',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ForTube',
    assets: ['DOT'],
    callFee: 1,
  },

  {
    id: 'fortube-usdt',
    logo: 'single-assets/USDT.svg',
    name: 'USDT',
    token: 'USDT',
    tokenDescription: 'ForTube',
    tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://tether.to',
    earnedToken: 'mooUSDT',
    earnedTokenAddress: '0xc16187F3eb15aEE949e7d784906d53fd7E364B26',
    earnContractAddress: '0xc16187F3eb15aEE949e7d784906d53fd7E364B26',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ForTube',
    assets: ['USDT'],
    callFee: 1,
  },

  {
    id: 'fortube-btcb',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'ForTube',
    tokenAddress: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://coinmarketcap.com/currencies/bitcoin-bep2/',
    earnedToken: 'mooBTCB',
    earnedTokenAddress: '0x6F0Df0858542fC4A1c7f82c14fAA490d7763592F',
    earnContractAddress: '0x6F0Df0858542fC4A1c7f82c14fAA490d7763592F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'ForTube',
    assets: ['BTC'],
    callFee: 1,
  },

  {
    id: 'fry-burger-v2',
    logo: 'single-assets/BURGER.png',
    name: 'BURGER v2',
    token: 'BURGER',
    tokenDescription: 'Retired Vault',
    tokenAddress: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/fryworld#farming-fries-with-burgers',
    earnedToken: 'mooFryBURGERv2',
    earnedTokenAddress: '0x2fd5f579e2cF9e3cb8127BBAd1B52c25750b1aCE',
    earnContractAddress: '0x2fd5f579e2cF9e3cb8127BBAd1B52c25750b1aCE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'coingecko',
    oracleId: 'burger-swap',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'fry',
    assets: ['BURGER'],
    callFee: 1,
  },

  {
    id: 'cake-syrup-twt',
    logo: 'single-assets/cakeTWT.png',
    name: 'CAKE TWT v1',
    token: 'CAKE',
    tokenDescription: 'Refunds available',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    tokenDescriptionUrl:
      'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-twt-with-cake',
    earnedToken: 'mooCakeTWT',
    earnedTokenAddress: '0x214e640c638fbe68Fb24e11BfBd8Cd7d6cb8E37b',
    earnContractAddress: '0x214e640c638fbe68Fb24e11BfBd8Cd7d6cb8E37b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'coingecko',
    oracleId: 'pancakeswap-token',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'refund',
    refundContractAddress: '0x2fd5caAB20191dcd4423F588e254CC298C7F66A1',
    platform: 'Pancake',
    assets: ['CAKE'],
    callFee: 0.5,
  },
  {
    id: 'fry-burger-v1',
    logo: 'single-assets/BURGER.png',
    name: 'BURGER v1',
    token: 'BURGER',
    tokenDescription: 'Please migrate to v2',
    tokenAddress: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
    tokenDecimals: 18,
    tokenDescriptionUrl: 'https://fry.world/',
    earnedToken: 'mooFryBURGER',
    earnedTokenAddress: '0x78d60Da18c09bdE41397010198F454021Ec810Ad',
    earnContractAddress: '0x78d60Da18c09bdE41397010198F454021Ec810Ad',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'coingecko',
    oracleId: 'burger-swap',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'fry',
    assets: ['BURGER'],
    callFee: 1,
  },
];
