import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import BannerSection from './sections/Banner';
import HightlightSection from './sections/Highlight';
import FeaturedInSection from './sections/FeaturedIn';
import InvestorsAndPartners from '../../assets/img/logo';
import FaqSection from './sections/Faq';

import styles from './index.style';

const useStyles = makeStyles(styles);



export default function HomePage() {

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      {/* <div className={classes.sectionTitle}>
        <span className={classes.sectionTitleLeft}>TheForce</span>
        <span className={classes.sectionTitleRight}>.Trade</span>
      </div>
      <div className={classes.sectionTitleDescription}>
        {t('TFT-Description')}
      </div>
      <div className={classes.menuBarAppContainer}>
        <MenuApp />
      </div> */}
      <BannerSection/>
      <HightlightSection/>
      <InvestorsAndPartners />
      <FeaturedInSection/>
      <FaqSection />
    </div>
  );
}
