import React, {useContext, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import GlobalContext from 'context';

import styles from './index.style';


const useStyles = makeStyles(styles);

export default () => {
  const { isNightMode } = useContext(GlobalContext);
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const language =
    i18n.language.split('-')[0]?.toLowerCase() === 'zh' ? 'zh' : 'en';
  const faq = [
    {title: 'FaqTitle1',content: 'FaqAnswer1',showAnswer: false},
    {title: 'FaqTitle2',content: 'FaqAnswer2',showAnswer: false},
    {title: 'FaqTitle3',content: 'FaqAnswer3',showAnswer: false},
    {title: 'FaqTitle4',content: 'FaqAnswer4',showAnswer: false},
    {title: 'FaqTitle5',content: 'FaqAnswer5',showAnswer: false},
    {title: 'FaqTitle6',content: 'FaqAnswer6',showAnswer: false},
    {title: 'FaqTitle7',content: 'FaqAnswer7',showAnswer: false},
    {title: 'FaqTitle8',content: 'FaqAnswer8',showAnswer: false},
    {title: 'FaqTitle9',content: 'FaqAnswer9',showAnswer: false}
   ]
const [faqlist, setfaq] = useState(faq)
const [show, setshow] = useState(false)
  return (
    <div className={classes.faq}>
         <div className={classes.container}>
            <div className={classes.title}>
            Frequently Asked Questions
            </div>
           {
             faqlist.map((item, index) => {
               return (
                 <>
                  <div className={classes.head} onClick={
                      ev=> {
                        let f = [...faqlist]
                        f[index].showAnswer = !f[index].showAnswer
                        setfaq(f)
                      }
                    }>
                    {t(item.title)}
                    <img src={require('assets/img/Shape.png')} width={9} height={6} style={{marginLeft: '20px'}}></img>
                  </div>
                  <span className={classes.content} style={{display: item.showAnswer?'':'none'}}>
                      {t(item.content)}
                  </span>
                  
                </>
               )
             })
           }
            
         </div>
    </div>
  );
};
