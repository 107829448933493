import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import RoadMapSection from './sections/RoadMap';
import TokenSection from './sections/TokenInfo';

import styles from './index.style';

const useStyles = makeStyles(styles);

const MenuApp = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <a className={classes.menuBarApp} href="https://app.theforce.trade">
      {t('Launch-App')}
    </a>
  );
};

export default function HomePage() {

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <TokenSection/>
      <RoadMapSection />
    </div>
  );
}
