import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import GlobalContext from 'context';

import styles from './index.style';


const useStyles = makeStyles(styles);

const MenuApp = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
    <a className={classes.menuBarApp} href="https://stake.theforce.trade/" target="_blank">
      {t('DeFi App')}
    </a>
    <a className={classes.menuBarAppW} href="https://nft.theforce.trade/"  target="_blank">
    {t('NFT App')}
  </a>
  </>
  );
};
export default () => {
  const { isNightMode } = useContext(GlobalContext);
  const classes = useStyles();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const language =
    i18n.language.split('-')[0]?.toLowerCase() === 'zh' ? 'zh' : 'en';

  return (
    <div className={classes.banner}>
       <div className={classes.container}>
         <div className={classes.littleTitle}>{t('WHO WE ARE')}</div>
         <div className={classes.content}>
           TheForce.Trade <br></br>
          {t("Slogan")}
          </div>
          <div className={classes.extra}>
            {t("Intro")}
            
          </div>
          <div className={classes.menuBarAppContainer}>
            <MenuApp />
          </div>
         

       </div>
    </div>
  );
};
