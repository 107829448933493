import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';

import styles from './styles';

const useStyles = makeStyles(styles);

const menuBar = [
  {
    name: 'Home',
    path: '/index',
  },
  {
    name: 'DeFi',
    url: 'https://stake.theforce.trade/',
  },
  {
    name: 'NFT-Platform',
    url: 'https://nft.theforce.trade/',
  },
  {
    name: 'Launchpad',
    url: 'https://launchpad.theforce.trade/',
  },
  {
    name: 'Market',
    // tip: 'Coming-Soon',
    url: 'https://market.theforce.trade',
  },
  {
    name: 'Docs',
    url: 'https://docs.theforce.trade',
  },
  {
    name: 'About',
    path: '/about',
  },
  {
    name: 'Buy FOC',
    url: 'https://pancakeswap.finance/swap?outputCurrency=0x3051cfb958dcd408fba70256073adba943fdf552',
  },
];

const MenuApp = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
 
  return (
    <a
      className={classes.menuBarApp}
      href="https://app.theforce.trade"
    >
      {t('Launch-App')}
    </a>
  )
}

const MenuList = (props) => {
  const { isNightMode, handleDrawerToggle } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [urlLocation, setLocation] = useState(useLocation())

  const handleSnackbar = (msg) =>
    enqueueSnackbar(msg, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });

  const jumpTo = (target) => () => {
    if (target.path) {
      // history.push(target.path);
      window.open(target.path, '_self');

    } else if (target.url) {
      window.open(target.url);
    } else {
      handleSnackbar(t(target.tip || 'Under-Development'));
    }
    handleDrawerToggle();
  }

  return (
    <div className={classes.menuListContainer}>
      {menuBar.map((item, i) => (
        item.name == 'Buy FOC' ?"":(
        <div className={(i === 0 && (urlLocation.pathname == '/' || urlLocation.pathname == '/index')|| item.name === 'About' && (urlLocation.pathname == '/about')) ? classes.menuListActiveItem : classes.menuListItem} key={item.name} onClick={jumpTo(item)} style={i === menuBar.length - 1 ? { borderBottom: 'none' } : {}}>
          <span>{t(item.name)}</span>
          <div className={(i === 0 && (urlLocation.pathname == '/' || urlLocation.pathname == '/index')|| item.name === 'About' && (urlLocation.pathname == '/about')) ? classes.menuListActiveItemBaseline : classes.menuListItemBaseline}></div>
        </div>)
      ))}
    </div>
  )
}

const MenuBar = props => {
  const { isNightMode } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [urlLocation, setLocation] = useState(useLocation())
  const handleSnackbar = (msg) =>
    enqueueSnackbar(msg, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });

  const jumpTo = (target) => () => {
    if (target.path) {
      // history.push(target.path);
      window.open(target.path, '_self');
    } else if (target.url) {
      window.open(target.url);
    } else {
      handleSnackbar(t(target.tip || 'Under-Development'));
    }
  };

  return (
    <div className={classes.menuBarContainer}>
      {menuBar.map((item, i) => (
        item.name == 'Buy FOC' ? (
          <div className={classes.buyButton} 
          key={item.name}
          onClick={jumpTo(item)}
          style={{ marginRight: '10px' }}>
            {t(item.name)}
          </div>
        ): (
        <div
          className={(i === 0 && (urlLocation.pathname == '/' || urlLocation.pathname == '/index') || item.name === 'About' && (urlLocation.pathname == '/about')) ? classes.menuBarActiveItem : classes.menuBarItem}
          key={item.name}
          onClick={jumpTo(item)}
        >
          <span>{t(item.name)}</span>
          <div
            className={(i === 0 && (urlLocation.pathname == '/' || urlLocation.pathname == '/index')|| item.name === 'About' && (urlLocation.pathname == '/about'))  ? classes.menuBarActiveItemBaseline : classes.menuBarItemBaseline}
          ></div>
        </div>
          )
      ))}
    </div>
  );
};

const Header = ({
  isNightMode,
  toggleNightMode,
}) => {
  const [lng, setLanguage] = useState('en');
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const cachedLanguage = i18n.language;
    if (!cachedLanguage) {
      return;
    }

    const languageCode = cachedLanguage.split('-')[0].toLowerCase();
    setLanguage(languageCode);
  }, [i18n.language]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = event => {
    switch (i18n.language.split('-')[0].toLowerCase()) {
      case 'zh':
        return i18n.changeLanguage('en').then(() => console.log(lng) || setLanguage('en'));
      case 'en':
        return i18n.changeLanguage('zh').then(() => setLanguage('zh'));
      // TODO: more translations
      default:
        return;
    }
  };

  return (
    <>
      <AppBar className={`${classes.appBar}`}>
        <Toolbar className={classes.container}>
          <Hidden lgUp>
            <div className={classes.drawerIcon} onClick={handleDrawerToggle}>
              <img
                src={require(`assets/img/menu@${
                  isNightMode ? 'dark' : 'light'
                }.png`)}
                alt="menu"
                height={'15px'}
              />
            </div>
          </Hidden>
          <a href="/" className={classes.title}>
            <Hidden xsDown>
              <img
                alt="large"
                src={require(`assets/img/logo@${
                  isNightMode ? 'dark' : 'light'
                }.large.png`)}
                height={'40px'}
                className={classes.logo}
              />
            </Hidden>
            <Hidden smUp>
              <img
                alt="small"
                src={require(`assets/img/logo@${
                  isNightMode ? 'dark' : 'light'
                }.large.png`)}
                height={'25px'}
                className={classes.logo}
              />
            </Hidden>
          </a>

          <div className={classes.menuBarContainer}></div>
          <Hidden mdDown>
            <MenuBar isNightMode={isNightMode} />
          </Hidden>

          {/* <div
            className={classes.iconButton}
            onClick={toggleNightMode}
            style={{ marginRight: '10px' }}
          >
            <img
              src={require(`assets/img/${
                isNightMode ? 'light' : 'dark'
              }_mode.png`)}
              height={'15px'}
              alt="mode"
            />
          </div>  */}
          <Hidden lgUp>
          <div className={classes.buyButton} 
          onClick={ev => {
            window.open(menuBar[menuBar.length -1].url)
          }}
          style={{ marginRight: '10px' }}>
            {menuBar[menuBar.length -1].name}
          </div>
          </Hidden>


          <div className={classes.iconButton} onClick={handleClick}>
            <img
              src={require(`assets/img/translate${
                lng === 'zh'
                  ? '.selected'
                  : isNightMode
                  ? '@dark.unselected'
                  : '@light.unselected'
              }.png`)}
              height={'15px'}
              alt="mode"
            />
          </div>
        </Toolbar>

        <Hidden lgUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <div
              className={classes.appBarHolder}
              style={{ marginBottom: 0 }}
            ></div>
            <MenuList
              isNightMode={isNightMode}
              handleDrawerToggle={handleDrawerToggle}
            />
          </Drawer>
        </Hidden>
      </AppBar>
      <div className={classes.appBarHolder}></div>
    </>
  );
};

export default Header;
